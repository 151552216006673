export const headers = [
  {
    label: 'sourcePost',
    key: 'sourcePost.name'
  },
  {
    label: 'title',
    key: 'title'
  },
  {
    label: 'Iinit',
    key: 'Iinit'
  },
  {
    label: 'IAnormal',
    key: 'IAnormal'
  },
  {
    label: 'year',
    key: 'year'
  }
];
