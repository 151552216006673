/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { Popover } from 'antd';
import { SplitCellsOutlined } from '@ant-design/icons';
import { useSchemaContext } from '../SchemaContext';
import {
  formatNum,
  handleData,
  getDRData,
  getDRMaxData,
  checkTRLinked,
  styles
} from '../utils';
import Lines from '../../utils/schemaLines';
import OpenedClosedSwitch from '../OpenClosedSwtich';

/**
 * DemieRameMapping component
 *
 * Renders a mapping of demie rames with their associated data and controls.
 *
 * @param {Object} props - The properties object.
 * @param {Array} props.demierames - The array of demie rames to be displayed.
 * @param {number} props.intNumber - The initial number for the internal numbering.
 * @param {string} props.mode - The mode in which the component is operating.
 * @param {function} props.update - The function to call when updating a demie rame.
 * @param {boolean} props.canEdit - Flag indicating whether the component can be edited.
 * @param {Array} props.tableData - The data for the table.
 * @param {Array} props.ATData - The array of ATData.
 * @param {Object} props.offset - The offset value for positioning.
 * @param {Object} props.projection - The projection object for transformations.
 * @param {function} props.setLocationTransfo - Function to set the location of the transformation.
 * @param {Object} props.elementsRef - Reference to the elements.
 *
 * @return {JSX.Element|null} The rendered demie rame mapping component or null if no demie rames are provided.
 */
const DemieRameMapping = ({
  demierames,
  intNumber,
  mode,
  update,
  canEdit,
  tableData,
  ATData,
  offset,
  projection,
  setLocationTransfo,
  elementsRef
}) => {
  const { transformersElement, selectedYear, getATforDR, schema } =
    useSchemaContext();
  const [isLoading, setIsLoading] = useState(true);
  const [AutoTRArray, setAutoTRArray] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      for (let i = 0; i < demierames.length; i += 1) {
        const data = await getATforDR(demierames[i].id);
        if (data) setAutoTRArray((prev) => [...prev, data]);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [demierames, getATforDR]);

  const getLineColor = (item, transformers) => {
    const transformer = transformers.find((tr) =>
      tr.link.some((lnk) => lnk.title === item.title)
    );
    if (
      !item.electric_switch_in &&
      item.electric_switch_out &&
      item.electric_switch_between
    ) {
      const linkedDemiRameId = item.link_switch_between_id;
      const linkedDemiRame = schema?.transformers
        .flatMap((tr) => tr.children)
        .find((child) => child.id === linkedDemiRameId);
      const linkedTransformer = transformers.find((tr) =>
        tr.link.some((lnk) => lnk.title === linkedDemiRame.title)
      );
      const color = linkedTransformer?.color || 'grey';
      return color;
    }
    return transformer?.color || 'grey';
  };

  const demiRameLocationInfo = (el, item, index) => {
    let lineDirection;
    if (demierames.length === 1) {
      lineDirection = 2;
    } else if (index === 0) {
      lineDirection = 0;
    } else {
      lineDirection = 1;
    }
    if (el) {
      // eslint-disable-next-line no-param-reassign
      elementsRef.current[item.title] = {
        _id: item.id,
        title: item.title,
        location: el.getBoundingClientRect(),
        isLinkedTo: transformersElement.filter(
          (tr) => tr.link.filter((lnk) => lnk.title === item.title)?.length > 0
        )[0]?.title,
        link_out: transformersElement.filter(
          (tr) => tr.id === item?.link_switch_out_id
        )[0]?.title,
        type: 'demi-rame',
        lineDirection,
        link_in_closed: item.electric_switch_in,
        link_out_closed: item.electric_switch_out
      };
    }
  };

  useEffect(() => {
    setLocationTransfo((prev) => ({
      ...prev,
      ...elementsRef.current
    }));
  }, [demierames, setLocationTransfo]);

  const buttons = demierames?.map((item) => [
    item.electric_switch_in,
    item.electric_switch_out,
    item.electric_switch_between,
    !!item.link_switch_out_id
  ]);

  return demierames?.map((item, index) => {
    const { transit, puissance, tension } = getDRData(
      tableData?.filter((data) => data.demiRame === item.id) || []
    );
    const { transitMax, puissanceMax, tensionMax } = getDRMaxData(
      tableData?.filter((data) => data.demiRame === item.id) || []
    );
    const buttonsLocation = [
      { top: 0, bottom: 0, left: 0 },
      { top: 0, bottom: 0, left: 0 }
    ];

    const color = getLineColor(item, transformersElement);

    const [toMap, setToMap] = useState([]);
    const [toMap2, setToMap2] = useState([]);

    useEffect(() => {
      const mapped = buttonsLocation.map((btn) => (
        <Lines
          key={Math.random()}
          x={btn.left - offset.x - 10}
          y={btn.bottom - offset.y + 10}
          height={btn.bottom - btn.top + 22}
          color={
            (item?.electric_switch_between && item?.electric_switch_out) ||
            item?.electric_switch_in
              ? color
              : 'grey'
          }
        />
      ));
      if (index === 1) {
        const mapped2 = (
          <Lines
            x={buttonsLocation[0].left - offset.x - 10}
            y={buttonsLocation[0].top - offset.y - 40}
            height={20}
            color={
              (item?.electric_switch_in && item?.electric_switch_out) ||
              (!item?.electric_switch_in &&
                item?.electric_switch_out &&
                item?.electric_switch_between)
                ? color
                : 'grey'
            }
          />
        );
        setToMap2(mapped2);
      }
      setToMap([mapped]);
    }, [isLoading, item, offset.x, offset.y, color, index]);

    return (
      <div
        key={item.title}
        ref={(el) => demiRameLocationInfo(el, item, index)}
        className="demirame-wrapper"
      >
        <div className="at-wrapper-entry">
          {AutoTRArray[index]
            ?.filter(
              (AT) =>
                AT.demirame === demierames[index]?.id &&
                AT.position === (index === 1 ? '2' : '1')
            )
            ?.map((data) => (
              <Popover
                key={data.name}
                placement="right"
                content={
                  <table className="schema-table at-table">
                    <tbody>
                      <tr key={item.id}>
                        <td rowSpan="2" className="at-name">
                          <span
                            style={{
                              backgroundColor: data?.color
                            }}
                          >
                            <SplitCellsOutlined />
                            AT {data?.name}
                          </span>
                        </td>
                        <th style={styles.tableHeader}>
                          Puissance installée (MVA)
                        </th>
                        <th style={styles.tableHeader}>Charge</th>
                        <th style={styles.tableHeader}>
                          Tension au primaire (kV)
                        </th>
                        <th style={styles.tableHeader}>
                          Tension secondaire (kV)
                        </th>
                        <th style={styles.tableHeader}>
                          Intensité au primaire (A)
                        </th>
                      </tr>
                      <tr key={item.id}>
                        <td style={styles.tableContent}>
                          {data?.installedPower}
                        </td>
                        <td style={styles.tableContent}>
                          {(index === 0 && buttons[index][0]) ||
                          (index === 1 &&
                            buttons[index - 1][2] &&
                            buttons[index][1])
                            ? `${
                                mode === 'Ptmb'
                                  ? (ATData &&
                                      ATData[`${data?._id}`]?.charge) ||
                                    0
                                  : (ATData &&
                                      ATData[`${data?._id}`]?.chargeMax) ||
                                    0
                              }%`
                            : 0}
                        </td>
                        <td style={styles.tableContent}>
                          {(index === 0 && buttons[index][0]) ||
                          (index === 1 &&
                            buttons[index - 1][2] &&
                            buttons[index][1])
                            ? (ATData && ATData[`${data?._id}`]?.Uentree) || 0
                            : 0}
                        </td>
                        <td style={styles.tableContent}>
                          {(index === 0 && buttons[index][0]) ||
                          (index === 1 &&
                            buttons[index - 1][2] &&
                            buttons[index][1])
                            ? (ATData && ATData[`${data?._id}`]?.u_sortie) || 0
                            : 0}
                        </td>
                        <td style={styles.tableContent}>
                          {(index === 0 && buttons[index][0]) ||
                          (index === 1 &&
                            buttons[index - 1][2] &&
                            buttons[index][1])
                            ? mode === 'Ptmb'
                              ? (ATData && ATData[`${data?._id}`]?.Ientree) || 0
                              : (ATData &&
                                  ATData[`${data?._id}`]?.IentreeMax) ||
                                0
                            : 0}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              >
                <span className="at-popover-btn">
                  <SplitCellsOutlined />
                  AT
                </span>
              </Popover>
            ))}
        </div>
        <OpenedClosedSwitch
          opened={index === 1 ? buttons[index][1] : buttons[index][0]}
          name={`Int ${index * 2 + intNumber}`}
          onClick={() => canEdit && update(index, index === 1 ? 'out' : 'in')}
          canEdit={canEdit}
          key={item.id}
          fct={(el) => {
            if (!el) return;
            const data = buttonsLocation[0];
            data.top = el.getBoundingClientRect().bottom;
            data.left = el.getBoundingClientRect().left;
            buttonsLocation[0] = data;
          }}
        />
        <table className="schema-table">
          <tbody>
            <tr key={item.id}>
              <td
                style={{
                  color: `${
                    formatNum(tensionMax) === formatNum(0.0) ? 'grey' : color
                  }`
                }}
              >
                <span>1/2 Rame {item.title}</span>
              </td>
              <th style={styles.tableHeader}>Transit (A)</th>
              <th style={styles.tableHeader}>Puissance (MVA)</th>
              {!selectedYear?.length ? (
                <th style={styles.tableHeader}>Tension (kV)</th>
              ) : null}
            </tr>
            {item.children.map((child) => (
              <tr key={child.id}>
                <td className="depart-title">
                  <span
                    style={{
                      ...styles.tableContent,
                      backgroundColor: `${
                        handleData(child.id, 'tension', mode, tableData) ===
                        formatNum(0.0)
                          ? 'grey'
                          : checkTRLinked(
                              color,
                              tableData,
                              transformersElement,
                              child
                            )
                      }`,
                      color: 'white'
                    }}
                  >
                    {`${child?.cell_number || 'N/A'} ${child.title}`}
                  </span>
                </td>
                <td style={styles.tableContent}>
                  {handleData(child.id, 'transit', mode, tableData, projection)}
                </td>
                <td style={styles.tableContent}>
                  {handleData(
                    child.id,
                    'puissance',
                    mode,
                    tableData,
                    projection
                  )}
                </td>
                {!selectedYear?.length ? (
                  <td style={styles.tableContent}>
                    {handleData(
                      child.id,
                      'tension',
                      mode,
                      tableData,
                      projection
                    )}
                  </td>
                ) : null}
              </tr>
            ))}
            <tr key={item._id}>
              <td />
              <td style={styles.tableHeader}>
                {mode === 'Ptmb' ? formatNum(transit) : formatNum(transitMax)}
              </td>
              <td style={styles.tableHeader}>
                {mode === 'Ptmb'
                  ? formatNum(puissance)
                  : formatNum(puissanceMax)}
              </td>
              {!selectedYear?.length ? (
                <td style={styles.tableHeader}>
                  {mode === 'Ptmb' ? formatNum(tension) : formatNum(tensionMax)}
                </td>
              ) : null}
            </tr>
          </tbody>
        </table>

        <OpenedClosedSwitch
          opened={index === 1 ? buttons[index][0] : buttons[index][1]}
          name={`Int ${index * 2 + intNumber + 1}`}
          onClick={() => canEdit && update(index, index === 1 ? 'in' : 'out')}
          canEdit={canEdit}
          fct={(el) => {
            if (!el) return;
            const data = buttonsLocation[0];
            data.bottom = el.getBoundingClientRect().top;
            buttonsLocation[0] = data;
            const data2 = buttonsLocation[1];
            data2.top = el.getBoundingClientRect().bottom;
            buttonsLocation[1] = data2;
          }}
        />
        <div className="at-wrapper-exit">
          {AutoTRArray[index]
            ?.filter(
              (AT) =>
                AT.demirame === demierames[index]?.id &&
                AT.position === (index === 1 ? '1' : '2')
            )
            ?.map((data) => (
              <Popover
                key={data.name}
                placement="right"
                content={
                  <table className="schema-table at-table">
                    <tbody>
                      <tr key={item.id}>
                        <td rowSpan="2" className="at-name">
                          <span
                            style={{
                              backgroundColor: data?.color
                            }}
                          >
                            <SplitCellsOutlined />
                            AT {data?.name}
                          </span>
                        </td>
                        <th style={styles.tableHeader}>
                          Puissance installée (MVA)
                        </th>
                        <th style={styles.tableHeader}>Charge</th>
                        <th style={styles.tableHeader}>
                          Tension au primaire (kV)
                        </th>
                        <th style={styles.tableHeader}>
                          Tension secondaire (kV)
                        </th>
                        <th style={styles.tableHeader}>
                          Intensité au primaire (A)
                        </th>
                      </tr>
                      <tr key={item.id}>
                        <td style={styles.tableContent}>
                          {data?.installedPower}
                        </td>
                        <td style={styles.tableContent}>
                          {(index === 1 && buttons[index][0]) ||
                          (index === 0 &&
                            buttons[index][2] &&
                            buttons[index][1]) ||
                          (buttons[index][1] && buttons[index][3])
                            ? `${
                                mode === 'Ptmb'
                                  ? (ATData &&
                                      ATData[`${data?._id}`]?.charge) ||
                                    0
                                  : (ATData &&
                                      ATData[`${data?._id}`]?.chargeMax) ||
                                    0
                              }%`
                            : 0}
                        </td>
                        <td style={styles.tableContent}>
                          {(index === 1 && buttons[index][0]) ||
                          (index === 0 &&
                            buttons[index][2] &&
                            buttons[index][1]) ||
                          (buttons[index][1] && buttons[index][3])
                            ? (ATData && ATData[`${data?._id}`]?.Uentree) || 0
                            : 0}
                        </td>
                        <td style={styles.tableContent}>
                          {(index === 1 && buttons[index][0]) ||
                          (index === 0 &&
                            buttons[index][2] &&
                            buttons[index][1]) ||
                          (buttons[index][1] && buttons[index][3])
                            ? (ATData && ATData[`${data?._id}`]?.u_sortie) || 0
                            : 0}
                        </td>
                        <td style={styles.tableContent}>
                          {(index === 1 && buttons[index][0]) ||
                          (index === 0 &&
                            buttons[index][2] &&
                            buttons[index][1]) ||
                          (buttons[index][1] && buttons[index][3])
                            ? mode === 'Ptmb'
                              ? (ATData && ATData[`${data?._id}`]?.Ientree) || 0
                              : (ATData &&
                                  ATData[`${data?._id}`]?.IentreeMax) ||
                                0
                            : 0}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                }
              >
                <span className="at-popover-btn">
                  <SplitCellsOutlined />
                  AT
                </span>
              </Popover>
            ))}
        </div>
        {demierames.length === 2 && index === 0 && (
          <OpenedClosedSwitch
            opened={buttons[index][2]}
            name="between"
            onClick={() => canEdit && update(index, 'between')}
            canEdit={canEdit}
            fct={(el) => {
              if (!el) return;
              const data = buttonsLocation[1];
              data.bottom = el.getBoundingClientRect().top;
              data.left = el.getBoundingClientRect().left;
              buttonsLocation[1] = data;
            }}
          />
        )}
        {toMap}
        {toMap2}
      </div>
    );
  });
};

export default DemieRameMapping;
