import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components/ListResource/ListResource';
import { useFormatter } from '../../utils/formatter';
import { transferHeaders } from './transferHeaders';
import { useTransferColumns } from './transferColumns';

const { Option } = Select;

const ListD12Transfer = ({
  projectEnums,
  sourcePosts,
  selectedSP,
  setSelectedSP,
  isLoading,
  yearsColumn,
  forceRefresh
}) => {
  const { t } = useTranslation();
  const { formattedData } = useFormatter();

  const columns = useTransferColumns({
    projectEnums,
    yearsColumn
  });

  return sourcePosts.length ? (
    <ListResource
      resourceName="D12_transfert"
      dataToFetch="D12"
      populate="sourcePost.departData transformerData"
      extraQuery={`status=YES&case_status!=IN_PROGRESS&sourcePost=${selectedSP}&typeOfCharge=TRF`}
      columns={columns}
      headers={transferHeaders}
      resourceModelName="D12"
      editAction={false}
      showAction={false}
      withCreateButton={false}
      withImportButton={false}
      exportUrl="D12"
      deleteAction={false}
      customActionColumn
      forceRefresh={forceRefresh}
      formatter={formattedData}
      extraButtons={
        <Row gutter={[8, 0]}>
          <Col>
            <Select
              loading={isLoading}
              style={{ width: 250 }}
              placeholder={t('import.selectedSourcePost')}
              onSelect={(data) => {
                setSelectedSP(data);
              }}
              defaultValue={selectedSP}
            >
              {(sourcePosts || []).map((sp) => (
                <Option key={sp._id} value={sp._id}>
                  {sp.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      }
    />
  ) : (
    []
  );
};

export default ListD12Transfer;

ListD12Transfer.propTypes = {
  projectEnums: PropTypes.arrayOf({
    caseStatus: PropTypes.arrayOf({})
  }).isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  sourcePosts: PropTypes.arrayOf({}),
  selectedSP: PropTypes.string,
  setSelectedSP: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  yearsColumn: PropTypes.arrayOf({}).isRequired
};

ListD12Transfer.defaultProps = {
  sourcePosts: [],
  selectedSP: null
};
