import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListImport from './ListImport';
import { ImportContextProvider } from './ImportContext';

/**
 * Router for managing import-related routes.
 *
 * @component
 * @returns {ReactNode} JSX element representing the import router
 */
export const ImportRouter = () => (
  <ImportContextProvider>
    <Routes>
      <Route index element={<ListImport />} />
    </Routes>
  </ImportContextProvider>
);
