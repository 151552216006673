import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Select, Row, notification } from 'antd';
import { useSchemaContext } from './SchemaContext';
import { theseus } from '../utils/createPipe';
import Lines from '../utils/schemaLines';
import { handleData, canSwitch, SelectButton } from './utils';
import TransformersMapping from './SchemaElementsMapping/TransformersMapping';
import ColumnMapping from './SchemaElementsMapping/ColumnsMapping';

const { Option } = Select;

/**
 * TransformersSchema component
 *
 * Renders the entire transformers schema including transformers and columns mappings.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.canEdit - Flag indicating whether the schema can be edited.
 * @param {Object} props.results - The results data containing various calculations and transformers data.
 * @param {function} props.getResource - The function to fetch resource data.
 * @param {boolean} props.isSchemaLoading - Flag indicating whether the schema is loading.
 * @param {boolean} props.isSchemaReady - Flag indicating whether the schema is ready to be displayed.
 * @param {function} props.setIsSchemaReady - The function to set the schema ready state.
 * @param {Array} props.lines - The array of line objects to be drawn.
 * @param {function} props.setLines - The function to set the lines state.
 *
 * @return {JSX.Element|null} The rendered transformers schema component or null if the schema is loading.
 */
const TransformersSchema = ({
  canEdit,
  results,
  getResource,
  isSchemaLoading,
  isSchemaReady,
  setIsSchemaReady,
  lines,
  setLines
}) => {
  const {
    transformersElement,
    schema,
    setSchema,
    patchSchema,
    patchDemiRame,
    refYear,
    setRefYear,
    selectedYear,
    setSelectedYear,
    yearsImport,
    yearsProject,
    origin,
    switchAlreadyUsed
  } = useSchemaContext();
  const [mode, setMode] = useState(origin === 'rescue' ? 'Pmax' : 'Ptmb');
  const [tableData, setTableData] = useState([]);
  const [ATData, setATData] = useState([]);
  const [canDisableTR, setCanDisableTR] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [projection, setProjection] = useState(false);
  const [locationTransfo, setLocationTransfo] = useState({});
  const elementsRef = useRef({});

  useEffect(() => {
    if (!switchAlreadyUsed) setLocationTransfo({});
    if (results?.projetCalculs?.length) {
      setProjection(true);
    } else {
      setProjection(false);
    }
    if (results) {
      setTableData(results?.projetCalculs);
      setATData(results?.ATCalculations);
    }
  }, [results, switchAlreadyUsed]);

  useEffect(() => {
    if (origin === 'rescue') {
      setCanDisableTR(true);
    }
  }, [origin]);

  useEffect(() => {
    if (schema.transformers.length && Object.keys(locationTransfo).length > 0) {
      setLines(theseus(locationTransfo));
    }
  }, [schema.transformers.length, locationTransfo]);

  const width =
    transformersElement?.length > 1
      ? `${Math.max(40, 80 / transformersElement.length)}em`
      : '40em';

  const update = async (column, demirame, type, callback = false) => {
    const otherDemiRameIndex = demirame === 0 ? 1 : 0;
    const newSchema = { ...schema };
    const base = newSchema.transformers[column].children[demirame];
    let change;
    switch (type) {
      case 'in':
        newSchema.transformers[column].children[demirame].electric_switch_in =
          !base.electric_switch_in;
        change = `Demie Rame '${
          newSchema.transformers[column].children[demirame].title
        }' interrupteur entrant => '${
          !base.electric_switch_in ? 'Ouvert' : 'Fermé'
        }'`;
        break;
      case 'out':
        newSchema.transformers[column].children[demirame].electric_switch_out =
          !base.electric_switch_out;
        change = `Demie Rame '${
          newSchema.transformers[column].children[demirame].title
        }' interrupteur sortant => '${
          !base.electric_switch_out ? 'Ouvert' : 'Fermé'
        }'`;
        break;
      case 'between':
        newSchema.transformers[column].children[
          demirame
        ].electric_switch_between = !base.electric_switch_between;
        newSchema.transformers[column].children[
          otherDemiRameIndex
        ].electric_switch_between = base.electric_switch_between;
        change = `Demie Rame '${
          newSchema.transformers[column].children[demirame].title
        }' interrupteur intermédiaire => '${
          !base.electric_switch_between ? 'Ouvert' : 'Fermé'
        }'`;
        break;
      default:
        break;
    }

    if (!canSwitch(newSchema.transformers[column])) {
      notification.warn({
        message: 'Action impossible',
        key: 'switch_invalid_position'
      });
      update(column, demirame, type, true);
      return;
    }

    await setSchema(newSchema);
    let typeOfPatch = 'TR';
    if (origin === 'projects') {
      typeOfPatch = 'PR';
    }
    if (origin === 'rescue') {
      typeOfPatch = 'RE';
    }

    await patchSchema(typeOfPatch);

    if (callback) return;

    await patchDemiRame({
      ...newSchema.transformers[column].children[demirame],
      change
    });
    if (newSchema.transformers[column].children.length > 1) {
      await patchDemiRame({
        ...newSchema.transformers[column].children[1],
        electric_switch_between:
          newSchema.transformers[column].children[demirame]
            .electric_switch_between
      });
    }
    getResource();
  };

  const checkSchemaReady = (el) => {
    if (!el || isSchemaReady) return;
    setOffset({
      x: el.getBoundingClientRect().x - 38,
      y: el.getBoundingClientRect().y
    });
    setIsSchemaReady(true);
  };

  return (
    !isSchemaLoading && (
      <div
        style={{ marginBottom: (schema?.transformers?.length || 6) * 20 }}
        ref={(el) => {
          checkSchemaReady(el);
        }}
      >
        {isSchemaReady &&
          !isSchemaLoading &&
          tableData &&
          ATData &&
          lines.map((line) => (
            <Lines
              key={line.x + Math.random()}
              x={line.x - offset.x}
              y={line.y - offset.y}
              height={line.height}
              width={line.width}
              color={line.color}
            />
          ))}
        <Row align="middle" justify="space-between" className="schema-top-menu">
          <Row>
            <SelectButton
              name="Ptmb"
              isSelected={mode === 'Ptmb'}
              onClick={() => setMode('Ptmb')}
            />
            <SelectButton
              name="P*max"
              isSelected={mode === 'Pmax'}
              onClick={() => setMode('Pmax')}
            />
          </Row>
          <Row style={{ flexDirection: 'row-reverse', gap: 8 }}>
            <Select
              style={{ width: 100 }}
              onSelect={(data) => {
                setSelectedYear(data);
              }}
              allowClear
              onClear={() => setSelectedYear()}
              value={selectedYear}
            >
              {(yearsProject || []).map((y) => (
                <Option key={y} value={y}>
                  {y}
                </Option>
              ))}
            </Select>
            <Select
              style={{ width: 100 }}
              onSelect={(data) => {
                setRefYear(data);
              }}
              value={refYear}
            >
              {(yearsImport || []).map((y) => (
                <Option key={y} value={y}>
                  {y}
                </Option>
              ))}
            </Select>
          </Row>
        </Row>
        <div
          style={{
            display: 'inline-flex',
            minWidth: `${Math.max(
              100,
              parseInt(width.replace('em', ''), 10) * transformersElement &&
                transformersElement.length
            )}%`
          }}
        >
          <Row
            className="transformer-row"
            style={{ marginBottom: (schema?.transformers?.length || 6) * 12 }}
          >
            {isSchemaReady && (
              <TransformersMapping
                width={width}
                mode={mode}
                totals={results?.totalTransformers}
                canDisableTR={canDisableTR}
                projection={projection}
                setLocationTransfo={setLocationTransfo}
                handleData={handleData}
                elementsRef={elementsRef}
              />
            )}
          </Row>
        </div>
        <div
          style={{
            display: 'inline-flex',
            minWidth: `${Math.max(
              100,
              parseInt(width.replace('em', ''), 10) * transformersElement &&
                transformersElement.length
            )}%`
          }}
        >
          {isSchemaReady && (
            <ColumnMapping
              width={width}
              mode={mode}
              update={update}
              canEdit={canEdit}
              tableData={tableData}
              ATData={ATData}
              offset={offset}
              projection={projection}
              setLocationTransfo={setLocationTransfo}
              elementsRef={elementsRef}
            />
          )}
        </div>
      </div>
    )
  );
};

export default TransformersSchema;

TransformersSchema.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  results: PropTypes.shape({
    totalTransformers: PropTypes.arrayOf(PropTypes.shape({})),
    departsCalculsInitiaux: PropTypes.arrayOf(PropTypes.shape({})),
    departsCalculsContractuels: PropTypes.arrayOf(PropTypes.shape({})),
    producteursCalculsInitiaux: PropTypes.arrayOf(PropTypes.shape({})),
    projetCalculs: PropTypes.arrayOf(PropTypes.shape({})),
    ATCalculations: PropTypes.shape({})
  }).isRequired,
  getResource: PropTypes.func.isRequired,
  isSchemaLoading: PropTypes.bool.isRequired,
  isSchemaReady: PropTypes.bool.isRequired,
  setIsSchemaReady: PropTypes.func.isRequired,
  lines: PropTypes.arrayOf(PropTypes.shape({})),
  setLines: PropTypes.func.isRequired
};

TransformersSchema.defaultProps = {
  lines: []
};
