export const headers = [
  {
    label: 'department',
    key: 'sourcePost.department'
  },
  {
    label: 'codeGdo',
    key: 'codeGdo'
  },
  {
    label: 'chargeName',
    key: 'chargeName'
  },
  {
    label: 'typeOfCharge',
    key: 'changes.typeOfCharge'
  },
  {
    label: 'case_identifier',
    key: 'case_identifier'
  },
  {
    label: 'case_status',
    key: 'case_status'
  },
  {
    label: 'yearImp',
    key: 'yearImp'
  },
  {
    label: 'projectPowerFois',
    key: 'projectPowerFois'
  },
  {
    label: 'comment',
    key: 'comment'
  }
];
