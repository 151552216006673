import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import useSourcePostContext from './SourcePostContext';

/**
 * Functional component for listing source posts.
 *
 * @returns {JSX.Element} ListSourcePosts component.
 */
const ListSourcePosts = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const { users } = useSourcePostContext();
  const isSuperAdmin = user.role === 'admins:SUPER-ADMIN';

  const headers = [
    {
      label: 'regionalDirection',
      key: 'regionalDirection.name'
    },
    {
      label: 'codeGdo',
      key: 'codeGdo'
    },
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'department',
      key: 'department'
    },
    {
      label: 'contact',
      key: 'contact.first_name'
    },
    {
      label: 'contact',
      key: 'contact.last_name'
    }
  ];

  const columns = [
    {
      title: t('sourceposts.form.regionalDirection'),
      key: 'regionalDirection',
      dataIndex: 'regionalDirection',
      render: (regionalDirection) => regionalDirection?.name,
      sorter: true
    },
    {
      title: t('sourceposts.form.codeGdo'),
      key: 'codeGdo',
      dataIndex: 'codeGdo',
      sorter: true
    },
    {
      title: t('sourceposts.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('sourceposts.form.department'),
      key: 'department',
      dataIndex: 'department',
      sorter: true
    },
    {
      title: t('sourceposts.form.contact'),
      key: 'contact',
      dataIndex: 'contact',
      render: (contact) => `${contact?.first_name} ${contact?.last_name}`,
      ...(isSuperAdmin
        ? {
            filters: (users || []).map((item) => ({
              text: `${item.first_name} ${item.last_name}`,
              value: `${item.first_name} ${item.last_name}`
            }))
          }
        : []),
      sorter: true
    }
  ];

  return (
    <ListResource
      resourceName="sourceposts"
      columns={columns}
      headers={headers}
      canEditOrDeleteFct={(sourcePost) =>
        user.role === 'admins:SUPER-ADMIN' ||
        sourcePost?.contact?._id === user._id
      }
      populate="regionalDirection contact"
      resourceModelName="SourcePost"
    />
  );
};

export default ListSourcePosts;
