/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { PageHeaderCustom } from '../../../components';
import { routes } from '../../../utils/constants/adminRoutes';
import Schema from '../../../schemaTool/Schema';
import Projects from './Projects';
import PrincipalSourcePostData from './PrincipalSourcePostData';
import { SchemaContextProvider } from '../../../schemaTool/SchemaContext';
import useSourcePostContext from '../SourcePostContext';
import { useAuthContext } from '../../../contexts/AuthContext';

const { TabPane } = Tabs;

/**
 * Renders the ShowSourcePost component.
 * @returns {JSX.Element} The JSX element representing the ShowSourcePost component.
 */
const ShowSourcePost = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { selectedTab, setSelectedTab, message, dispatchAPI } =
    useSourcePostContext();
  const [isLoading, setIsLoading] = useState(false);
  const [sourcePost, setSourcePost] = useState(null);
  const [canEdit, setCanEdit] = useState(false);
  const [yearInit, setYearInit] = useState();

  const findYearInit = (weatherDatas) => {
    const lastDate = new Date(
      Math.max(
        ...weatherDatas.map((weatherData) => new Date(weatherData.yearMES))
      )
    );
    setYearInit(Number(moment(lastDate).format('YYYY')));
  };

  useEffect(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sourceposts/${id}`
      });
      setCanEdit(
        user.role === 'admins:SUPER-ADMIN' ||
          data?.contact === user._id ||
          data?.contact?._id === user._id
      );
    } catch (e) {
      message(e);
    }
  }, [user, id]);

  const getSourcePost = useCallback(
    async (source, idProject) => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/${source}/${
            idProject || id
          }?populate=departData projects weatherData demiRameData`
        });
        if (data.weatherData.length) findYearInit(data.weatherData);
        setSourcePost(data);
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    },
    [id]
  );

  useEffect(() => {
    (async () => {
      await getSourcePost('sourceposts');
    })();
  }, [getSourcePost]);

  const deleteSourcePost = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/sourceposts/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleTabChange = (key) => {
    setSelectedTab(key);
  };

  const tabsContent = [
    {
      label: `Schéma`,
      key: '1',
      children: <Schema id={id} origin="sourceposts" />
    },
    {
      label: `Projets`,
      key: '2',
      children: <Projects id={id} origin="sourceposts" />
    },
    {
      label: `Informations sur le poste source`,
      key: '3',
      children: (
        <PrincipalSourcePostData
          sourcePost={sourcePost}
          isLoading={isLoading}
          id={id}
          origin="sourceposts"
        />
      )
    }
  ];

  return (
    <SchemaContextProvider
      sourcePost={sourcePost}
      getSourcePost={getSourcePost}
      yearInit={yearInit}
    >
      <PageHeaderCustom
        title={t('sourceposts.show.title')}
        extra={
          canEdit && (
            <>
              <Link to={{ pathname: `${routes.SOURCEPOSTS}/edit/${id}` }}>
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deleteSourcePost}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </>
          )
        }
      />
      {sourcePost && (
        <Tabs
          activeKey={selectedTab}
          onChange={handleTabChange}
          className="source-post-tabs"
        >
          {tabsContent.map((tab) => (
            <TabPane tab={tab.label} key={tab.key}>
              {tab.children}
            </TabPane>
          ))}
        </Tabs>
      )}
    </SchemaContextProvider>
  );
};

export default ShowSourcePost;
