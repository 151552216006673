import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Row, Avatar, Card, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import moment from 'moment';
import parse from 'html-react-parser';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { routes } from '../../utils/constants/adminRoutes';
import BgImage from '../../assets/images/alexis-antoine-m1s1SDdbCQY-unsplash.jpg';

const { Meta } = Card;

/**
 * Component to display details of a single article.
 * @returns {JSX.Element} JSX.Element
 */
const ShowArticle = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [article, setArticle] = useState(null);

  const formattedContent = (text) => {
    const pattern = /<.*?>/g;
    return pattern.test(text) ? parse(text) : text;
  };

  const getArticle = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/articles/${id}?populate=regionalDirection,contact`
      });
      setArticle(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getArticle();
    })();
  }, [getArticle]);

  const deleteArticle = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/articles/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('articles.show.title')}
        extra={
          <>
            <Link to={{ pathname: `${routes.NEWS}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteArticle}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Row gutter={[0, 24]}>
          <Skeleton
            loading={isLoading}
            title={0}
            paragraph={0}
            avatar={{ size: 64 }}
            active
          >
            <Avatar
              size={64}
              src={article && article.photo ? article.photo : BgImage}
            />
          </Skeleton>
        </Row>
        <Row justify="center">
          <Skeleton active loading={isLoading} paragraph={{ rows: 5 }}>
            <Row justify="center">
              <Card
                className="card-show"
                hoverable
                bordered={false}
                cover={
                  article && (
                    <img
                      alt={article?.principal}
                      src={article?.photo || BgImage}
                    />
                  )
                }
              >
                <Meta
                  title={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <h3>{article?.principal}</h3>
                      <p style={{ fontSize: 12 }}>
                        {moment(article?.created_at).format('DD.MM.YYYY')}
                      </p>
                    </div>
                  }
                  description={
                    <p className="card-text-description">
                      {article?.introducy}
                    </p>
                  }
                />
                <p>{formattedContent(article?.content)}</p>
                <Row align="end">
                  <div style={{ flexDirection: 'column' }}>
                    <p>
                      <Tag color={article?.regionalDirection?.color}>
                        {article?.regionalDirection?.name}
                      </Tag>
                    </p>
                    <p>{`Auteur: ${article?.contact?.first_name || '-'} ${
                      article?.contact?.last_name || '-'
                    }`}</p>
                  </div>
                </Row>
              </Card>
            </Row>
          </Skeleton>
        </Row>
      </ContentCustom>
    </>
  );
};

export default ShowArticle;
