import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Table, Row, Col, Button } from 'antd';
import { ContentCustom } from '../../components';
import { PageHeaderCustom } from '../../components/PageHeader/PageHeader';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import ModificationModal from './ModificationModal';
import { useColumns } from './columns';

const { Option } = Select;

/**
 * Component for displaying a list of historical data.
 *
 * @returns {React.Element} React component
 */
const ListHistory = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [sourcePostsIds, setSourcePostsIds] = useState([]);
  const [sourcePost, setSourcePost] = useState([]);
  const [id, setId] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const modes = ['Sourcepost', 'Research', 'Project', 'Rescue'];
  const [currentMode, setCurrentMode] = useState(modes[0]);
  const [modifiedData, setModifiedData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = (data) => {
    setModifiedData(data);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModifiedData({});
  };

  const getSourcePosts = async (mode) => {
    try {
      const url = `/${mode}`;
      const { data } = await dispatchAPI('GET', {
        url
      });
      setSourcePostsIds(data.map((el) => ({ _id: el._id, name: el.name })));
    } catch (e) {
      message(e);
    }
  };

  useEffect(() => {
    getSourcePosts('sourceposts');
  }, []);

  useEffect(async () => {
    try {
      if (!id) return;
      const { data } = await dispatchAPI('GET', {
        url: `/sourceposts/${id}?populate=historic.author`
      });
      const DRids = [];
      data?.schemaInit?.transformers?.forEach((element) =>
        element.children.forEach((DR) => {
          DRids.push(DR.id);
        })
      );
      const data2 = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const DRid of DRids) {
        const { data: DR } = await dispatchAPI('GET', {
          url: `/demirames/${DRid}?populate=historic.author`
        });
        data2.push(...(DR?.historic?.filter((el) => el.title) || []));
      }
      setSourcePost([...(data?.historic || []), ...data2]);
    } catch (e) {
      message(e);
    }
  }, [id]);

  const replaceModes = (mode) => {
    switch (mode) {
      case 'Sourcepost':
        return 'Poste Source';
      case 'Research':
        return 'R&D';
      case 'Project':
        return 'Projet';
      case 'Rescue':
        return 'Secours';
      default:
        return '';
    }
  };

  useEffect(() => {
    switch (currentMode) {
      case 'Sourcepost':
        return getSourcePosts('sourceposts');
      case 'Research':
        return getSourcePosts('researches');
      case 'Project':
        return getSourcePosts('projects');
      case 'Rescue':
        return getSourcePosts('Rescues');
      default:
        return '';
    }
  }, [currentMode]);

  const columns = useColumns({
    openModal,
    forceRefresh,
    setForceRefresh,
    currentMode,
    id,
    message
  });

  return (
    <>
      <ModificationModal
        modifiedData={modifiedData}
        modalOpen={modalOpen}
        closeModal={closeModal}
      />
      <PageHeaderCustom title={t(`history.title`)} />
      <ContentCustom>
        <>
          <Row justify="space-between" align="middle">
            <Row gutter={[8, 0]}>
              {modes.map((mode) => (
                <Col>
                  <Button
                    key={mode}
                    type={currentMode === mode ? 'primary' : 'default'}
                    onClick={() => {
                      setId('');
                      setSourcePost([]);
                      setCurrentMode(mode);
                    }}
                  >
                    {replaceModes(mode)}
                  </Button>
                </Col>
              ))}
            </Row>
            <Select
              placeholder={t('history.show.selectedSourcePost')}
              style={{ width: 250 }}
              onSelect={(data) => {
                setId(data);
              }}
            >
              {(sourcePostsIds || []).map((y) => (
                <Option key={y._id} value={y._id}>
                  {y.name}
                </Option>
              ))}
            </Select>
          </Row>
          <Table
            columns={columns}
            dataSource={
              sourcePost
                ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                ?.map((el) => ({
                  ...el,
                  details: `${el.title} ${JSON.stringify(el?.newValue) || ''}`
                })) || []
            }
          />
        </>
      </ContentCustom>
    </>
  );
};

export default ListHistory;
