import React, { createContext, useContext, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const ImportContext = createContext({});

/**
 * Context provider for managing import-related state.
 *
 * @component
 * @param {object} props - Props for the component
 * @param {ReactNode} props.children - The child components
 * @returns {ReactNode} JSX element representing the context provider
 */
export const ImportContextProvider = ({ children }) => {
  const [year, setYear] = useState(moment().format('YYYY'));

  return (
    <ImportContext.Provider
      value={{
        year,
        setYear
      }}
    >
      {children}
    </ImportContext.Provider>
  );
};

ImportContextProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default () => useContext(ImportContext);
