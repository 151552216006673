import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Spin, Row, Col, Card, Timeline, Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { ContentCustom, PageHeaderCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { routes } from '../../utils/constants/adminRoutes';
import { historicDotType } from '../../utils/constants/tagColors';
import DonutChart from './DonutChart';
import ModificationModal from '../history/ModificationModal';

/**
 * Component for displaying the home dashboard.
 *
 * @returns {React.Element} React component
 */
export const Home = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [modifiedData, setModifiedData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = (data) => {
    setModifiedData(data);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModifiedData({});
  };

  const getResource = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/dashboard`
      });
      setResults(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getResource();
    })();
  }, [getResource]);

  return isLoading ? (
    <Spin
      spinning={isLoading}
      size="large"
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%'
      }}
    />
  ) : (
    <>
      <ModificationModal
        modifiedData={modifiedData}
        modalOpen={modalOpen}
        closeModal={closeModal}
      />
      <PageHeaderCustom title={t('home.title')} />
      <ContentCustom>
        <Row className="dashboard-row" gutter={[0, 24]}>
          {results.map((result) => (
            <Col span={24}>
              <Card
                className="dashboard-card"
                title={result.name}
                bordered
                extra={
                  <Link
                    to={{
                      pathname: `${routes.SOURCEPOSTS}/show/${result._id}`
                    }}
                  >
                    <EyeOutlined
                      style={{
                        fontSize: 18,
                        color: 'var(--primaryColor)'
                      }}
                    />
                  </Link>
                }
              >
                <Row>
                  <Col xs={24} lg={8} className="dashboard-timeline-col">
                    <Timeline mode="left">
                      {result.historic
                        .filter((f) => f.pin)
                        .sort((a, b) => new Date(b.date) - new Date(a.date))
                        .map((h) => (
                          <Timeline.Item
                            label={moment(h.date).format('DD.MM.YYYY HH:mm')}
                            color={historicDotType[h.type]}
                          >
                            <p>{`${t(`history.tags.${h.type}`)} de ${t(
                              `history.column.${h.modelName}`
                            )}`}</p>
                            <p>
                              {h?.title?.startsWith(
                                'Modification des données'
                              ) ? (
                                <Button
                                  onClick={() => openModal(h)}
                                  icon={<EyeOutlined />}
                                >
                                  {h?.title?.split('de')[2]}
                                </Button>
                              ) : (
                                h?.title
                              )}
                            </p>
                          </Timeline.Item>
                        ))}
                    </Timeline>
                  </Col>
                  <Col
                    xs={{ span: 24, offset: 0 }}
                    lg={{ span: 12, offset: 4 }}
                  >
                    <DonutChart
                      dataAdmin={[
                        {
                          type: 'Ptmb consommée totale',
                          value: result?.total_de_np
                        },
                        {
                          type: 'Ptmb projet activé',
                          value: result?.total_projects
                        },
                        {
                          type: 'Ptmb secours contractuels',
                          value: result?.total_cont_relief
                        },
                        {
                          type: 'Ptmb restante',
                          // eslint-disable-next-line no-unsafe-optional-chaining
                          value: result?.total_tr_np - result?.total_de_np
                        }
                      ]}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </ContentCustom>
    </>
  );
};
