import React, { useCallback, useEffect, useState } from 'react';
import { Select, InputNumber, DatePicker } from 'antd';
import useSourcePostContext from '../SourcePostContext';
import useResearchContext from '../../research/ResearchContext';
import useRescueContext from '../../rescue/RescueContext';

const { Option } = Select;

/**
 * Custom hook to generate form fields for a project.
 * @param {object} t - The translation function from react-i18next.
 * @param {string} id - The ID of the source post.
 * @returns {object} An object containing form fields.
 */
const useFields = (t, id) => {
  const sourcePostContext = useSourcePostContext();
  const researchContext = useResearchContext();
  const rescueContext = useRescueContext();
  const projectEnums =
    sourcePostContext?.projectEnums ||
    researchContext?.projectEnums ||
    rescueContext?.projectEnums;
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);

  const getSelectOptions = useCallback(() => {
    setIsFieldsLoading(true);
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['case_identifier'],
      rules: [{ required: true }]
    },
    {
      name: ['case_status'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(projectEnums?.caseStatus || []).map((cs) => (
            <Option key={cs} value={cs}>
              {t(`projects.form.enums.${cs}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['status'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(projectEnums?.status || [])
            .filter((f) => f !== 'ARCHIVED')
            .map((charge) => (
              <Option key={charge} value={charge}>
                {t(`projects.form.enums.${charge}`)}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['yearTO'],
      rules: [{ required: true }],
      input: <DatePicker />
    },
    {
      name: ['yearMES'],
      rules: [{ required: true }],
      input: <DatePicker picker="year" />
    },
    {
      name: ['achievmentTime'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      name: ['sourcePost'],
      rules: [{ required: true }],
      initialValue: id,
      hidden: true
    },
    {
      name: ['comment']
    }
  ];

  return {
    fields
  };
};

export default useFields;
