import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SourcePostContextProvider } from '../sourcePost/SourcePostContext';
import ListHistory from './ListHistory';

/**
 * Router component for managing history-related routes.
 *
 * This component provides routing for history-related functionality, such as viewing
 * and managing history records. It wraps the ListHistory component with the
 * SourcePostContextProvider to provide access to source post data.
 *
 * @returns {JSX.Element} HistoryRouter component
 */
export const HistoryRouter = () => (
  <SourcePostContextProvider>
    <Routes>
      <Route index element={<ListHistory />} />
    </Routes>
  </SourcePostContextProvider>
);
