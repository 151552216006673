import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Popconfirm, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import {
  ContentCustom,
  PageHeaderCustom,
  ListResource
} from '../../components';
import { routes } from '../../utils/constants/adminRoutes';

const { Title } = Typography;

/**
 * Component for rendering detailed tabs related to a regional direction.
 *
 * @param {Object} props - Component props
 * @param {string} props.regionalDirection - ID of the regional direction
 * @returns {JSX.Element} DetailedTabs component
 */
const DetailedTabs = ({ regionalDirection }) => {
  const { t } = useTranslation();

  const headersSourcePost = [
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'code',
      key: 'code'
    }
  ];

  const columnsSourcePost = [
    {
      title: t('sourceposts.form.code'),
      key: 'code',
      dataIndex: 'code',
      sorter: true
    },
    {
      title: t('sourceposts.form.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    }
  ];

  DetailedTabs.propTypes = {
    regionalDirection: PropTypes.oneOfType(PropTypes.shape({})).isRequired
  };

  return (
    <>
      <Title level={4}>{`${t('regionaldirections.show.source_posts')} `}</Title>
      <ListResource
        resourceName="sourceposts"
        columns={columnsSourcePost}
        headers={headersSourcePost}
        extraQuery={`regionalDirection=${regionalDirection}`}
        showTitle={false}
        resourceModelName="Sourcepost"
        path="/sourcepost"
      />
    </>
  );
};

/**
 * Component for displaying detailed information about a regional direction.
 *
 * This component fetches and displays information about a specific regional direction,
 * allowing the user to edit or delete it.
 *
 * @returns {JSX.Element} ShowRegionalDirection component
 */
const ShowRegionalDirection = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [regionalDirection, setRegionalDirection] = useState(null);

  const getRegionalDirection = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/regionaldirections/${id}`
      });
      setRegionalDirection(data);
    } catch (e) {
      message(e);
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      await getRegionalDirection();
    })();
  }, [getRegionalDirection]);

  const deleteRegionalDirection = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/regionaldirections/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={regionalDirection?.name}
        extra={
          <>
            <Link to={{ pathname: `${routes.DR}/edit/${id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteRegionalDirection}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <DetailedTabs regionalDirection={id} />
      </ContentCustom>
    </>
  );
};

export default ShowRegionalDirection;
