import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Component for creating or updating an article.
 * @param {Object} props - The component props.
 * @param {string} props.purpose - The purpose of the component, either "create" to create a new article or "edit" to edit an existing article.
 * @returns {JSX.Element} The CreateUpdateArticle component.
 */
const CreateUpdateArticle = ({ purpose }) => {
  const [content, setContent] = useState('');
  const { fields, isFieldsLoading, base64 } = useFields({
    purpose,
    setContent
  });
  const { user } = useAuthContext();

  const config = {
    onGetResource: {
      setFields: (data) => ({
        ...data
      })
    },
    onUpdateResource: {
      setBody: (data) => ({
        ...data,
        ...(base64 ? { photo: base64 } : {}),
        content
      })
    },
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        ...(base64 ? { photo: base64 } : {}),
        contact: user._id,
        content
      })
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="articles"
      resource="articles"
      config={config}
    />
  );
};

CreateUpdateArticle.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateArticle;
