/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component to render lines on a diagram.
 * @component
 * @param {object} props - The props of the Lines component.
 * @param {number} props.x - The x-coordinate of the starting point of the line.
 * @param {number} props.y - The y-coordinate of the starting point of the line.
 * @param {number} [props.height=4] - The height of the line.
 * @param {number} [props.width=4] - The width of the line.
 * @param {string} props.color - The color of the line.
 * @returns {JSX.Element} A div element representing the line.
 */
const Lines = ({ x, y, height = 4, width = 4, color }) => (
  <div
    data-testid="lines"
    style={{
      top: height < 0 ? y : y - height,
      left: width < 0 ? x : x - width,
      width: Math.abs(width),
      height: Math.abs(height),
      backgroundColor: color
    }}
    className="schema-line"
  />
);

export default Lines;

Lines.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string
};
