import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/adminRoutes';
import ListRescue from './ListRescue';
import ShowRescue from './ShowRescue';
import CreateUpdateRescue from './CreateUpdateRescue';
import CreateUpdateProjects from '../sourcePost/ShowSourcePost/CreateUpdateProjects';
import CreateUpdateWeather from '../sourcePost/ShowSourcePost/CreateUpdateWeather';
import CreateUpdateProducer from '../sourcePost/ShowSourcePost/CreateUpdateProducer';
import CreateUpdateContractualRelief from '../sourcePost/ShowSourcePost/CreateUpdateContractualRelief';
import { RescueContextProvider } from './RescueContext';
import ShowProject from '../sourcePost/ShowSourcePost/ShowProject';
import UpdateElements from '../sourcePost/ShowSourcePost/SchemaElements/UpdateElements';

/**
 * Defines the router for managing rescue-related routes.
 * This router includes routes for listing, showing, creating, and editing rescues,
 * as well as routes for managing related projects, weather, producer, contractual relief,
 * transformers, columns, demirames, departures, and autotransformers.
 *
 * @component
 * @returns {JSX.Element} A JSX element representing the RescueRouter component.
 */
export const RescueRouter = () => (
  <RescueContextProvider>
    <Routes>
      <Route index element={<ListRescue />} />
      <Route path="/show/:id" element={<ShowRescue />} />
      <Route path="/create" element={<CreateUpdateRescue purpose="create" />} />
      <Route path="/edit/:id" element={<CreateUpdateRescue purpose="edit" />} />
      <Route
        path={`/${subRoutes.PROJECTS}/create`}
        element={<CreateUpdateProjects purpose="create" model="rescue" />}
      />
      <Route
        path={`/${subRoutes.PROJECTS}/edit/:id`}
        element={<CreateUpdateProjects purpose="edit" model="rescue" />}
      />
      <Route
        path={`/${subRoutes.PROJECTS}/show/:id`}
        element={<ShowProject model="rescue" />}
      />
      <Route
        path={`/${subRoutes.WEATHER}/create`}
        element={<CreateUpdateWeather purpose="create" model="rescue" />}
      />
      <Route
        path={`/${subRoutes.WEATHER}/edit/:id`}
        element={<CreateUpdateWeather purpose="edit" model="rescue" />}
      />
      <Route
        path={`/${subRoutes.PRODUCER}/edit/:id`}
        element={<CreateUpdateProducer purpose="edit" model="rescue" />}
      />
      <Route
        path={`/${subRoutes.PRODUCER}/create`}
        element={<CreateUpdateProducer purpose="create" model="rescue" />}
      />
      <Route
        path={`/${subRoutes.CONTRACTUALRELIEF}/create`}
        element={
          <CreateUpdateContractualRelief purpose="create" model="rescue" />
        }
      />
      <Route
        path={`/${subRoutes.CONTRACTUALRELIEF}/edit/:id`}
        element={
          <CreateUpdateContractualRelief purpose="edit" model="rescue" />
        }
      />
      <Route
        path={`/${subRoutes.TRANSFORMER}/edit/:id`}
        element={
          <UpdateElements purpose="edit" element="transformer" model="rescue" />
        }
      />
      <Route
        path={`/${subRoutes.COLONNE}/edit/:id`}
        element={
          <UpdateElements purpose="edit" element="colonne" model="rescue" />
        }
      />
      <Route
        path={`/${subRoutes.DEMIRAME}/edit/:id`}
        element={
          <UpdateElements purpose="edit" element="demirame" model="rescue" />
        }
      />
      <Route
        path={`/${subRoutes.DEPART}/edit/:id`}
        element={
          <UpdateElements purpose="edit" element="depart" model="rescue" />
        }
      />
      <Route
        path={`/${subRoutes.AT}/edit/:id`}
        element={
          <UpdateElements
            purpose="edit"
            element="autotransformer"
            model="sourceposts"
          />
        }
      />
    </Routes>
  </RescueContextProvider>
);
