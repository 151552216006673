import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../components/ListResource/ListResource';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useFormatter } from '../../utils/formatter';
import { headers } from './headers';
import { useColumns } from './columns';

/**
 * Component for listing research resources.
 *
 * This component displays a list of research resources, including their status, name,
 * source post, and creation date. It fetches data from the server and renders it in a table format.
 * Users with appropriate permissions can edit or delete individual research resources.
 *
 * @returns {React.ReactNode} The rendered component.
 */
const ListResearch = () => {
  const { t } = useTranslation();
  const { formattedData } = useFormatter();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [researchEnums, setResearchEnums] = useState({});

  const columns = useColumns(researchEnums, t);

  const getResearchEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/research/enums`
      });
      setResearchEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getResearchEnums();
    })();
  }, []);

  return (
    <ListResource
      resourceName="research"
      populate="sourcePost,contact"
      columns={columns}
      headers={headers}
      formatter={formattedData}
      canEditOrDeleteFct={(sourcePost) =>
        user.role === 'admins:SUPER-ADMIN' ||
        sourcePost?.contact?._id === user?._id
      }
      resourceModelName="research"
    />
  );
};

export default ListResearch;
