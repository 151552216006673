import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ShowUser from './ShowUser';
import ListUsers from './ListUsers';

/**
 * Defines the routing for user-related pages, including listing users and viewing user details.
 *
 * @returns {JSX.Element} The routes for user-related pages.
 */
export const UserRouter = () => (
  <Routes>
    <Route index element={<ListUsers />} />
    <Route path="/show/:id" element={<ShowUser />} />
  </Routes>
);
