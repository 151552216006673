import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { routes } from '../../utils/constants/adminRoutes';

/**
 * Generates columns for a research data table.
 * @param {Object} researchEnums - The research enums.
 * @param {Function} t - The translation function.
 * @returns {Array} An array of objects representing the table columns.
 */
export const useColumns = (researchEnums, t) => [
  {
    title: t('research.column.status'),
    key: 'status',
    dataIndex: 'status',
    filters: (researchEnums?.status || []).map((p) => ({
      text: t(`research.form.enums.${p}`),
      value: p
    })),
    render: (status) => t(`research.form.enums.${status}`),
    sorter: true
  },
  {
    title: t('research.column.name'),
    key: 'name',
    dataIndex: 'name',
    sorter: true
  },
  {
    title: t('research.column.sourcePost'),
    key: 'sourcePost',
    dataIndex: 'sourcePost',
    render: (sourcePost) => (
      <Link
        to={{
          pathname: `${routes.SOURCEPOSTS}/show/${sourcePost?._id}`
        }}
      >
        {sourcePost?.name}
      </Link>
    ),
    sorter: true
  },
  {
    title: t('research.column.created_at'),
    key: 'created_at',
    dataIndex: 'created_at',
    render: (created_at) =>
      (created_at && moment(created_at).format('DD/MM/YYYY')) || '-',
    sorter: true
  }
];
