import React from 'react';
import { Input, Typography, Button } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ColorSelector from '../ColorSelector';

/**
 * Provides the content for the General component.
 * @param {object} props - The props of the GeneralContent component.
 * @param {string} props.inputName - The input name.
 * @param {function} props.setATData - The function to update AT data.
 * @param {object} props.ATData - The AT data.
 * @param {function} props.setInputName - The function to set input name.
 * @param {string} props.color - The color.
 * @param {function} props.setColor - The function to set color.
 * @param {string} props.editType - The edit type.
 * @param {function} props.setCellNumber - The function to set cell number.
 * @param {string} props.cellNumber - The cell number.
 * @param {string} props.purpose - The purpose.
 * @param {Function} props.t - The translation function.
 * @param {object} props.selectedKeysInfo - The selected keys info.
 * @returns {Array} An array of objects representing the content for the General component.
 */
export const GeneralContent = ({
  inputName,
  setATData,
  ATData,
  setInputName,
  color,
  setColor,
  editType,
  setCellNumber,
  cellNumber,
  purpose,
  t,
  selectedKeysInfo
}) => [
  {
    key: 0,
    content: (
      <>
        <Typography>{t('schema.toolbar.name')}:</Typography>
        <Input
          allowClear
          style={{
            width: 100
          }}
          defaultValue={inputName}
          onChange={(e) => {
            if (editType === 'AT') {
              setATData({ ...ATData, name: e.target.value });
            }
            setInputName(e.target.value);
          }}
        />
      </>
    )
  },
  ...((editType === 'DE' && purpose === 'create') ||
  (editType === 'TR' &&
    purpose === 'edit' &&
    selectedKeysInfo.node.type !== 'TR') ||
  (editType === 'DE' &&
    purpose === 'edit' &&
    selectedKeysInfo.node.type === 'DE')
    ? [
        {
          key: 1,
          content: (
            <>
              <Typography>{t('schema.toolbar.cell_number')}:</Typography>
              <Input
                allowClear
                style={{
                  width: 100
                }}
                defaultValue={cellNumber}
                onChange={(e) => {
                  setCellNumber(e.target.value);
                }}
              />
            </>
          )
        }
      ]
    : []),
  {
    key: 2,
    content: (
      <>
        <Typography>{t('schema.toolbar.color')}:</Typography>
        <ColorSelector color={color} setColor={setColor} />
      </>
    )
  }
];

/**
 * Provides the content for the Buttons component.
 * @param {object} props - The props of the ButtonsContent component.
 * @param {function} props.checkWarningUpdate - The function to check warning update.
 * @param {function} props.setWarningColor - The function to set warning color.
 * @param {function} props.setWarningText - The function to set warning text.
 * @param {function} props.setwarningAT - The function to set warning AT.
 * @param {function} props.setwarningAtData - The function to set warning AT data.
 * @param {function} props.setPurpose - The function to set purpose.
 * @param {Function} props.t - The translation function.
 * @param {boolean} props.isLoadingButton - The loading state of the button.
 * @returns {Array} An array of objects representing the content for the Buttons component.
 */
export const ButtonsContent = ({
  checkWarningUpdate,
  setWarningColor,
  setWarningText,
  setwarningAT,
  setwarningAtData,
  setPurpose,
  t,
  isLoadingButton
}) => [
  {
    key: 9,
    content: (
      <div
        style={{
          display: 'inline-flex'
        }}
      >
        <Button
          loading={isLoadingButton}
          disabled={isLoadingButton}
          type="primary"
          onClick={() => checkWarningUpdate()}
        >
          <CheckOutlined /> {t(`buttons.validate`)}
        </Button>
        <Button
          type="link"
          onClick={() => {
            setWarningColor(false);
            setWarningText(false);
            setwarningAT(false);
            setwarningAtData(false);
            setPurpose('init');
          }}
        >
          <CloseOutlined /> {t(`buttons.cancel`)}
        </Button>
      </div>
    )
  }
];
