export const headers = [
  {
    label: 'status',
    key: 'status'
  },
  {
    label: 'name',
    key: 'name'
  },
  {
    label: 'sourcePost',
    key: 'sourcePost.name'
  },
  {
    label: 'created_at',
    key: 'created_at'
  },
  {
    label: 'contact',
    key: 'contact.first_name'
  },
  {
    label: 'contact',
    key: 'contact.last_name'
  }
];
