import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../../components/ListResource/ListResource';

/**
 * Component that displays autotransformer data in a list format.
 * @param {object} props - The props object.
 * @param {string} props.id - The ID of the source post.
 * @param {string} props.origin - The origin of the data.
 * @returns {JSX.Element} React component.
 */
const DemirameData = ({ id, origin }) => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'sourcePost',
      key: 'sourcePost.name'
    },
    {
      label: 'demiRame',
      key: 'demiRame.title'
    },
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'position',
      key: 'position'
    },
    {
      label: 'installedPower',
      key: 'installedPower'
    },
    {
      label: 'u_sortie',
      key: 'u_sortie'
    }
  ];

  const columns = [
    {
      title: t('sourceposts.show.atData.sourcePost'),
      key: 'sourcePost',
      dataIndex: 'sourcePost',
      render: ({ name }) => name,
      sorter: true
    },
    {
      title: t('sourceposts.show.atData.demiRame'),
      key: 'demirame',
      dataIndex: 'demirame',
      render: ({ title }) => title,
      sorter: true
    },
    {
      title: t('sourceposts.show.atData.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('sourceposts.show.atData.position'),
      key: 'position',
      dataIndex: 'position',
      render: (position) => t(`sourceposts.show.atData.${position}`),
      sorter: true
    },
    {
      title: t('sourceposts.show.atData.installedPower'),
      key: 'installedPower',
      dataIndex: 'installedPower',
      sorter: true
    },
    {
      title: t('sourceposts.show.atData.u_sortie'),
      key: 'u_sortie',
      dataIndex: 'u_sortie',
      sorter: true
    }
  ];
  return (
    <ListResource
      withCreateButton={false}
      showAction={false}
      deleteAction={false}
      resourceName="autotransformers"
      columns={columns}
      headers={headers}
      populate="sourcePost,demirame"
      extraQuery={`sourcePost=${id}&model=${origin}`}
      resourceModelName="Autotransformer"
      showTitle={false}
      path={`/${origin}/autotransformer`}
      withImportButton={false}
    />
  );
};

export default DemirameData;

DemirameData.propTypes = {
  id: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired
};
