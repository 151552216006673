import React from 'react';
import { useSchemaContext } from '../SchemaContext';
import DemieRameMapping from './DemieRameMapping';

/**
 * ColumnMapping component
 *
 * Renders a series of columns based on the provided schema, each containing a DemieRameMapping component.
 *
 * @param {Object} props - The properties object.
 * @param {number} props.width - The width of each column.
 * @param {string} props.mode - The mode in which the component is operating.
 * @param {function} props.update - The function to call when updating a demirame.
 * @param {boolean} props.canEdit - Flag indicating whether the columns can be edited.
 * @param {Array} props.tableData - The data for the table.
 * @param {Array} props.ATData - The ATData array.
 * @param {number} props.offset - The offset value for positioning.
 * @param {Object} props.projection - The projection object for transformations.
 * @param {function} props.setLocationTransfo - Function to set the location of the transformation.
 * @param {Object} props.elementsRef - Reference to the elements.
 *
 * @return {JSX.Element|null} The rendered column mapping component or null if no schema is provided.
 */
const ColumnMapping = ({
  width,
  mode,
  update,
  canEdit,
  tableData,
  ATData,
  offset,
  projection,
  setLocationTransfo,
  elementsRef
}) => {
  const { schema } = useSchemaContext();

  return (
    schema &&
    schema?.transformers?.map((column, index) => (
      <div key={column.id} style={{ width }} className="schema-column">
        <DemieRameMapping
          demierames={column?.children}
          intNumber={
            1 +
            schema.transformers
              .slice(0, index)
              .reduce((acc, transfo) => acc + transfo.children.length, 0) *
              2
          }
          mode={mode}
          update={(demirame, type) => update(index, demirame, type)}
          canEdit={canEdit}
          tableData={tableData}
          ATData={ATData}
          offset={offset}
          projection={projection}
          setLocationTransfo={setLocationTransfo}
          elementsRef={elementsRef}
        />
      </div>
    ))
  );
};

export default ColumnMapping;
