import React from 'react';
import PropTypes from 'prop-types';

/**
 * Toolbar format component for organizing toolbar blocks.
 * @component
 * @param {Object} props - The props of the component.
 * @param {Array} props.blocks - An array of toolbar blocks.
 * @returns {JSX.Element} - The rendered component.
 */
const ToolBarFormat = ({ blocks }) => {
  const width =
    blocks.filter((el) => !(el.content instanceof Array)).length === 3
      ? '40em'
      : '100%';
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        width,
        maxWidth: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-end'
      }}
    >
      {blocks
        .filter((el) => !(el.content instanceof Array))
        .map((block) => (
          <div key={block.key}>{block.content}</div>
        ))}
    </div>
  );
};

export default ToolBarFormat;
ToolBarFormat.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      content: PropTypes.element.isRequired
    })
  ).isRequired
};
