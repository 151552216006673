import { useTranslation } from 'react-i18next';

/**
 * Custom hook for generating columns for historical data.
 *
 * @param {string} year - SelectedYear
 * @returns {Array} Array of column configurations
 */
export const useColumns = ({ year }) => {
  const { t } = useTranslation();

  return [
    {
      title: t('sourceposts.show.departData.sourcePost'),
      key: 'sourcePost',
      dataIndex: 'sourcePost',
      render: ({ name }) => name,
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.cell'),
      key: 'title',
      dataIndex: 'title',
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.IAnormal'),
      key: 'IAnormalImports',
      dataIndex: 'IAnormalImports',
      render: (IAnormalImports) =>
        IAnormalImports ? IAnormalImports[year] || '' : '',
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.Iinit'),
      key: 'IinitImports',
      dataIndex: 'IinitImports',
      render: (IinitImports) => (IinitImports ? IinitImports[year] || '' : ''),
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.year'),
      key: 'year',
      dataIndex: 'year',
      render: () => year && year
    }
  ];
};
