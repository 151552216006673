import React from 'react';
import { InputNumber, DatePicker } from 'antd';

/**
 * Custom hook to generate form fields for a specific ID.
 *
 * @param {string} id - The ID for which form fields are generated.
 * @returns {Object} An object containing an array of form fields.
 */
const useFields = (id) => {
  const fields = [
    {
      name: ['PowerD12toPmax'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      name: ['NominalTempTN'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      name: ['MinimalTempTMB'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      name: ['DayTempTJ'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      name: ['TCMA'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      name: ['gradientG'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      name: ['cosPhi'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      name: ['yearMES'],
      rules: [{ required: true }],
      input: <DatePicker picker="year" />
    },
    {
      name: ['sourcePost'],
      rules: [{ required: true }],
      initialValue: id,
      hidden: true
    }
  ];

  return {
    fields
  };
};

export default useFields;
