export const transferHeaders = [
  {
    label: 'ebr',
    key: 'ebr'
  },
  {
    label: 'department',
    key: 'department'
  },
  {
    label: 'codeGdo',
    key: 'codeGdo'
  },
  {
    label: 'department_impacted',
    key: 'department_impacted'
  },
  {
    label: 'gdo_htb_impacted',
    key: 'gdo_htb_impacted'
  },
  {
    label: 'case_identifier',
    key: 'case_identifier'
  },
  {
    label: 'case_status',
    key: 'case_status'
  },
  {
    label: 'yearImp',
    key: 'yearImp'
  },
  {
    label: 'projectPowerFois',
    key: 'projectPowerFois'
  },
  {
    label: 'comment',
    key: 'comment'
  }
];
