import { useTranslation } from 'react-i18next';

/**
 * Custom hook for generating email validation pattern and error message.
 *
 * @returns {Array} An array containing an object with 'pattern' for email validation and 'message' for error message.
 */
export const useEmailPattern = () => {
  const { t } = useTranslation();

  return [
    {
      pattern:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: t('login.invalidMailFormat')
    }
  ];
};
