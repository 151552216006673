export const headers = [
  {
    label: 'principal',
    key: 'principal'
  },
  {
    label: 'introducy',
    key: 'introducy'
  },
  {
    label: 'content',
    key: 'content'
  },
  {
    label: 'created_at',
    key: 'created_at'
  },
  {
    label: 'updated_at',
    key: 'updated_at'
  }
];
