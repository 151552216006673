/**
 * Updates the transformer translator.
 * @param {object} props - The props for the updateTransformerTranslator function.
 * @param {function} props.setWarningText - The function to set warning text.
 * @param {function} props.setWarningColor - The function to set warning color.
 * @param {function} props.setwarningAT - The function to set warning AT.
 * @param {function} props.setwarningAtData - The function to set warning AT data.
 * @param {string} props.editType - The edit type.
 * @param {object} props.ATData - The AT data.
 * @param {function} props.postAT - The function to post AT.
 * @param {function} props.setPurpose - The function to set purpose.
 * @param {string} props.purpose - The purpose.
 * @param {function} props.addToSchema - The function to add to schema.
 * @param {string} props.inputName - The input name.
 * @param {string} props.cellNumber - The cell number.
 * @param {string} props.color - The color.
 * @param {string} props.linkSwitchIn - The link switch in.
 * @param {string} props.linkSwitchBetween - The link switch between.
 * @param {function} props.updateSchema - The function to update schema.
 * @param {object} props.selectedKeysInfo - The selected keys info.
 * @param {function} props.getSourcePost - The function to get source post.
 * @param {string} props.origin - The origin.
 * @returns {Promise<boolean>} A promise that resolves to true if the update is successful.
 */
export const updateTransformerTranslator = async ({
  setWarningText,
  setWarningColor,
  setwarningAT,
  setwarningAtData,
  editType,
  ATData,
  postAT,
  setPurpose,
  purpose,
  addToSchema,
  inputName,
  cellNumber,
  color,
  linkSwitchIn,
  linkSwitchBetween,
  updateSchema,
  selectedKeysInfo,
  getSourcePost,
  origin
}) => {
  setWarningText(false);
  setWarningColor(false);
  setwarningAT(false);
  setwarningAtData(false);
  if (editType === 'AT') {
    await postAT(ATData);
    setWarningColor(false);
    setWarningText(false);
    setwarningAT(false);
    setwarningAtData(false);
    setPurpose('init');
    getSourcePost(origin);
    return true;
  }
  setPurpose('init');
  switch (purpose) {
    case 'create':
      addToSchema({
        title: inputName,
        cell_number: cellNumber,
        color,
        type: editType,
        icon: editType,
        ...(editType !== 'TR'
          ? {
              children: []
            }
          : {}),
        ...(editType === 'TR'
          ? {
              link: []
            }
          : {}),
        ...(editType === 'RA'
          ? {
              electric_switch_in: true,
              electric_switch_out: true,
              electric_switch_between: false,
              link_switch_in: linkSwitchIn,
              link_switch_between: linkSwitchBetween
            }
          : {})
      });
      break;
    case 'edit':
      updateSchema({
        title: inputName,
        cell_number: cellNumber,
        color,
        type: selectedKeysInfo?.node?.type,
        icon: selectedKeysInfo?.node?.icon,
        id: selectedKeysInfo?.node?.id,
        children: selectedKeysInfo?.node?.children,
        ...(editType === 'RA'
          ? {
              electric_switch_in: true,
              electric_switch_out: true,
              electric_switch_between: false,
              link_switch_in: linkSwitchIn,
              link_switch_between: linkSwitchBetween
            }
          : {}),
        ...(editType === 'TR'
          ? {
              link: selectedKeysInfo?.node?.link
            }
          : {})
      });
      break;
    default:
      return true;
  }
  return true;
};
