import React from 'react';
import { InputNumber, Select, DatePicker } from 'antd';

const { Option } = Select;

/**
 * Hook for generating form fields for contractual relief data.
 * @param {string} id - The ID of the resource.
 * @param {Array} departs - The array of department data.
 * @returns {object} An object containing form fields.
 */
const useFields = (id, departs) => {
  const fields = [
    {
      name: ['customer'],
      rules: [{ required: true }]
    },
    {
      name: ['contractNumber'],
      rules: [{ required: true }]
    },
    {
      name: ['contractualPower'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      name: ['contractualTMB'],
      input: <InputNumber disabled />
    },
    {
      name: ['contractualTN'],
      input: <InputNumber disabled />
    },
    {
      name: ['powerTN'],
      input: <InputNumber disabled />
    },
    {
      name: ['powerTMB'],
      input: <InputNumber disabled />
    },
    {
      name: ['comment'],
      rules: [{ required: true }]
    },
    {
      name: ['sourcePost'],
      rules: [{ required: true }],
      initialValue: id,
      hidden: true
    },
    {
      name: ['depart'],
      rules: [{ required: true }],
      input: (
        <Select>
          {(departs || []).map((de) => (
            <Option key={de} value={de._id}>
              {de.title}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['yearMES'],
      rules: [{ required: true }],
      input: <DatePicker picker="year" />
    }
  ];

  return {
    fields
  };
};

export default useFields;
