import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../../components/ListResource/ListResource';

/**
 * Component that displays column data in a list format.
 * @param {object} props - The props object.
 * @param {string} props.id - The ID of the source post.
 * @param {string} props.origin - The origin of the data.
 * @returns {JSX.Element} React component.
 */
const ColonnesData = ({ id, origin }) => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'sourcePost',
      key: 'sourcePost.name'
    },
    {
      label: 'title',
      key: 'title'
    },
    {
      label: 'createdBy',
      key: 'createdBy.first_name'
    },
    {
      label: 'createdBy',
      key: 'createdBy.last_name'
    }
  ];

  const columns = [
    {
      title: t('sourceposts.show.colonneData.sourcePost'),
      key: 'sourcePost',
      dataIndex: 'sourcePost',
      render: ({ name }) => name,
      sorter: true
    },
    {
      title: t('sourceposts.show.colonneData.name'),
      key: 'title',
      dataIndex: 'title',
      sorter: true
    },
    {
      title: t('sourceposts.show.colonneData.createdBy'),
      key: 'createdBy',
      dataIndex: 'createdBy',
      render: ({ first_name, last_name }) => `${first_name} ${last_name}`,
      sorter: true
    }
  ];
  return (
    <ListResource
      withCreateButton={false}
      showAction={false}
      deleteAction={false}
      resourceName="colonnes"
      columns={columns}
      headers={headers}
      populate="sourcePost,createdBy,updatedBy"
      extraQuery={`sourcePost=${id}&model=${origin}`}
      resourceModelName="Colonne"
      showTitle={false}
      path={`/${origin}/colonne`}
      withImportButton={false}
    />
  );
};

export default ColonnesData;

ColonnesData.propTypes = {
  id: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired
};
