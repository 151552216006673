import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/**
 * Checks for warnings when updating or creating elements in the schema.
 * @param {string} purpose - Purpose of the operation ('create' or 'update').
 * @param {object} schema - The schema object.
 * @param {array} transformersElement - Array of transformer elements.
 * @param {string} inputName - Name of the input element.
 * @param {string} color - Color of the input element.
 * @param {function} setWarningText - Function to set warning text state.
 * @param {function} setWarningColor - Function to set warning color state.
 * @param {array} AutoTRArray - Array of automatic transformer elements.
 * @param {object} ATData - Automatic transformer data object.
 * @param {function} setwarningAT - Function to set automatic transformer warning state.
 * @param {function} setwarningAtData - Function to set automatic transformer data warning state.
 * @param {string} editType - Type of edit operation ('AT' or 'TR').
 * @returns {boolean} - Returns true if no warnings, false otherwise.
 */
export const checkWarningUpdate = (
  purpose,
  schema,
  transformersElement,
  inputName,
  color,
  setWarningText,
  setWarningColor,
  AutoTRArray,
  ATData,
  setwarningAT,
  setwarningAtData,
  editType
) => {
  const sameText = [];
  const duplicate = [];
  setWarningColor(false);
  setWarningText(false);
  setwarningAtData(false);
  setwarningAT(false);
  if (purpose === 'create') {
    (transformersElement || []).forEach((transformer) => {
      if (inputName === transformer.title) sameText.push(transformer.title);
      return sameText;
    });
    (schema.transformers || []).forEach((co) => {
      if (inputName === co.title) sameText.push(co.title);
      if (co.children) {
        co.children.forEach((ra) => {
          if (inputName === ra.title) sameText.push(ra.title);
          if (ra.children) {
            ra.children.forEach((de) => {
              if (inputName === de.title) sameText.push(de.title);
            });
          }
        });
      }
      return false;
    });
    if (!color) setWarningColor(true);
    if (!inputName || sameText.length) setWarningText(true);
    if (!color || !inputName || sameText.length) return false;
    if (editType === 'AT') {
      if (!ATData.demirame || !ATData.installedPower || !ATData.position) {
        setwarningAtData(true);
        return false;
      }
      if (ATData.demirame) {
        (AutoTRArray || []).forEach((at) => {
          if (
            at.demirame === ATData.demirame &&
            Number(at.position) === ATData.position
          ) {
            setwarningAT(true);
            duplicate.push(at.name);
          }
        });
      }
      if (duplicate.length) return false;
    }
    return true;
  }
  return true;
};

/**
 * Formats a number with optional unit.
 * @param {number} num - The number to format.
 * @param {string} unity - The unit to append (optional).
 * @returns {string} - The formatted number with unit.
 */
export const formatNum = (num, unity) => {
  if (num === undefined || num === null || num === '') return 'NA';
  if (Math.abs(num) > 1000000) return `${(num / 1000000).toFixed(2)}M`;
  if (typeof num === 'number') return `${num.toFixed(2)} ${unity || ''}`;
  return num;
};

/**
 * Determines the color for power calculation based on the selected transformer and type.
 * @param {object} selectedTR - The selected transformer object.
 * @param {string} type - The type of calculation (optional).
 * @returns {string} - The color for power calculation.
 */
const powerCalculColor = (selectedTR, type) => {
  const power = selectedTR?.power;
  const powerMax = selectedTR?.powerMax;
  const year = moment(selectedTR?.serviceYear).year();
  if (type === 'max') {
    if ((powerMax >= 1 && year < 1976) || (powerMax >= 1.1 && year > 1976))
      return 'red';
    if (powerMax >= 1 && powerMax <= 1.1 && year > 1976) return 'orange';
  }
  if ((power >= 1 && year < 1976) || (power >= 1.1 && year > 1976))
    return 'red';
  if (power >= 1 && power <= 1.1 && year > 1976) return 'orange';
  return 'black';
};

/**
 * Handles the data based on the provided parameters.
 * @param {string} id - The ID of the data.
 * @param {string} type - The type of data.
 * @param {string} mode - The mode of data handling.
 * @param {Array} totals - The array of total data.
 * @param {boolean} projection - Indicates whether the data is projected or not.
 * @returns {*} - The formatted data based on the provided parameters.
 */
export const handleData = (id, type, mode, totals, projection) => {
  let filtered;
  // Is a transformer
  if (projection && (totals?.[0]?.power || totals?.[0]?.power === 0)) {
    filtered = totals?.filter((data) => id === data._id) || [];
    // Is a depart
  } else {
    filtered = totals?.filter((data) => id === data.depart) || [];
  }
  if (mode === 'Ptmb') {
    switch (type) {
      case 'transit-total':
        return formatNum(filtered[0]?.totalItmbInit, 'A') || 'NA';
      case 'puissance-total':
        return formatNum(filtered[0]?.totalStmbInit) || 'NA';
      case 'percent':
        // eslint-disable-next-line no-case-declarations, no-unsafe-optional-chaining
        const total = formatNum(filtered[0]?.power * 100) || 'NA';
        return (
          <span
            style={{ color: powerCalculColor(filtered[0]) }}
          >{`${total}%`}</span>
        );
      case 'transit':
        return formatNum(filtered[0]?.ITmbPostProj) || 'NA';
      case 'puissance':
        return formatNum(filtered[0]?.STmbPostProj) || 'NA';
      case 'tension':
        return formatNum(filtered[0]?.voltage) || 'NA';
      default:
        return 0;
    }
  }
  switch (type) {
    case 'transit-total':
      return formatNum(filtered[0]?.totalIStarMaxProj, 'A') || 'NA';
    case 'puissance-total':
      return formatNum(filtered[0]?.totalSStarMaxProj) || 'NA';
    case 'percent':
      // eslint-disable-next-line no-case-declarations, no-unsafe-optional-chaining
      const total = formatNum((filtered[0]?.powerMax || 0) * 100) || 'NA';
      return (
        <span
          style={{ color: powerCalculColor(filtered[0], 'max') }}
        >{`${total}%`}</span>
      );
    case 'transit':
      return formatNum(filtered[0]?.IStarMaxPostProj) || 'NA';
    case 'puissance':
      return formatNum(filtered[0]?.SStarMaxPostProj) || 'NA';
    case 'tension':
      return formatNum(filtered[0]?.voltage) || 'NA';
    default:
      return 0;
  }
};

/**
 * Calculates the total data values for a transformer column.
 * @param {Array} tableData - The table data containing transformer information.
 * @returns {Object} - An object containing the total values for transit, power, and voltage.
 */
export const getDRData = (tableData) => {
  const transit =
    (tableData &&
      tableData.reduce((acc, data) => acc + (data?.ITmbPostProj || 0), 0)) ||
    0;
  const puissance =
    (tableData &&
      tableData.reduce((acc, data) => acc + (data?.STmbPostProj || 0), 0)) ||
    0;
  const tension = (tableData && tableData[0]?.voltage) || 0;
  return { transit, puissance, tension };
};

/**
 * Calculates the maximum data values for a transformer column.
 * @param {Array} tableData - The table data containing transformer information.
 * @returns {Object} - An object containing the maximum values for transit, power, and voltage.
 */
export const getDRMaxData = (tableData) => {
  const transitMax =
    (tableData &&
      tableData.reduce(
        (acc, data) => acc + (data?.IStarMaxPostProj || 0),
        0
      )) ||
    0;
  const puissanceMax =
    (tableData &&
      tableData.reduce(
        (acc, data) => acc + (data?.SStarMaxPostProj || 0),
        0
      )) ||
    0;

  const tensionMax = (tableData && tableData[0]?.voltage) || 0;
  return { transitMax, puissanceMax, tensionMax };
};

/**
 * Checks if switching is possible for a given transformer column.
 * @param {Object} column - The transformer column object.
 * @returns {boolean} - Returns true if switching is possible, false otherwise.
 */
export const canSwitch = (column) => {
  const demiRameLength = column?.children?.length || 0;
  if (demiRameLength === 0) return false;
  const nbSwitchTrue = column.children.reduce((acc, dr) => {
    let toAdd = 0;
    if (dr.electric_switch_in) toAdd += 1;
    if (dr.electric_switch_out) toAdd += 1;
    if (dr.electric_switch_between) toAdd += 1;
    return acc + toAdd;
  }, 0);
  const nbSwitch = Math.max(demiRameLength * 3, 0);
  if (nbSwitchTrue === nbSwitch) return false;
  if (demiRameLength === 1) {
    return !(
      column.children[0].electric_switch_in &&
      column.children[0].electric_switch_out
    );
  }
  return true;
};

/**
 * checkTRLinked function
 *
 * Checks and returns the appropriate color for a transformer based on its links.
 *
 * @param {string} color - The default color of the transformer.
 * @param {Array} tableData - The table data containing transformer link information.
 * @param {Array} transformers - The array of transformer objects.
 * @param {Object} data - The data object for the current transformer.
 *
 * @return {string} The checked color of the transformer based on its links.
 */
export const checkTRLinked = (color, tableData, transformers, data) => {
  let checkedColor = color;
  const linkedTrWithBetween = tableData?.find(
    (f) => f.linked_tr_with_between && f.title === data.title
  )?.linked_tr_with_between;
  const linkedOut = tableData?.find(
    (f) => f.linked_out && f.title === data.title
  )?.linked_out;
  if (linkedTrWithBetween) {
    checkedColor = (transformers || []).find(
      (f) => f.id === linkedTrWithBetween
    ).color;
  }
  if (linkedOut) {
    checkedColor = (transformers || []).find((f) => f.id === linkedOut).color;
  }
  return checkedColor;
};

/**
 * Styles object
 *
 * Contains the styles for table headers and table content.
 *
 * @property {Object} tableHeader - Styles for the table headers.
 * @property {string} tableHeader.backgroundColor - The background color of the table headers.
 * @property {string} tableHeader.justifyContent - The justification of the content within the table headers.
 * @property {string} tableHeader.textAlign - The text alignment within the table headers.
 * @property {Object} tableContent - Styles for the table content.
 * @property {string} tableContent.justifyContent - The justification of the content within the table content.
 * @property {string} tableContent.textAlign - The text alignment within the table content.
 *
 * @return {Object} The styles object containing table header and content styles.
 */
export const styles = {
  tableHeader: {
    backgroundColor: '#F1F2F3',
    justifyContent: 'center',
    textAlign: 'center'
  },
  tableContent: { justifyContent: 'center', textAlign: 'center' }
};

/**
 * checkIfTrCanBeDeleted function
 *
 * Checks if a transformer can be deleted by verifying if any demi-rame is attached to it.
 *
 * @param {Object} tr - The transformer object to check.
 * @param {Object} schema - The schema object containing transformers and their children.
 *
 * @return {boolean} Returns false if any demi-rame is attached to the transformer, otherwise returns true.
 */
export const checkIfTrCanBeDeleted = (tr, schema) =>
  !schema.transformers.some((col) =>
    col.children.some(
      (demiRame) =>
        demiRame.link_switch_in_id === tr.id ||
        demiRame.link_switch_out_id === tr.id
    )
  );

/**
 * SelectButton component
 *
 * Renders a button that can be selected or unselected.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.name - The name to display on the button.
 * @param {boolean} props.isSelected - Flag indicating whether the button is selected.
 * @param {function} props.onClick - The function to call when the button is clicked.
 *
 * @return {JSX.Element} The rendered select button component.
 */
export const SelectButton = ({ name, isSelected, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    style={{
      all: 'unset',
      backgroundColor: `${isSelected ? '#1423DC' : 'white'}`,
      color: `${isSelected ? 'white' : 'black'}`,
      border: `1px solid ${isSelected ? '#1423DC' : '#C8CBD0'}`,
      width: '5em',
      textAlign: 'center',
      borderRadius: 2
    }}
  >
    {name}
  </button>
);

SelectButton.propTypes = {
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};
