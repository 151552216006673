import moment from 'moment';
import useImportContext from '../routes/import/ImportContext';

/**
 * Custom hook for formatting data using moment.js and the year from the import context.
 *
 * @returns {object} An object containing a function 'formattedData' to format the provided data.
 */
export const useFormatter = () => {
  const { year } = useImportContext();
  const formattedData = (data) => ({
    ...data,
    year,
    IAnormal: data?.IAnormalImports ? data.IAnormalImports[year] || '' : '',
    Iinit: data?.IinitImports ? data.IinitImports[year] || '' : '',
    created_at:
      data?.created_at && moment(data?.created_at).format('DD/MM/YYYY'),
    yearMES: data?.yearMES && moment(data?.yearMES).format('DD/MM/YYYY'),
    yearImp: data?.yearImp && moment(data?.yearImp).format('YYYY')
  });
  return { formattedData };
};
