import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ListResource } from '../../components/ListResource/ListResource';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { routes } from '../../utils/constants/adminRoutes';
import { useFormatter } from '../../utils/formatter';
import { headers } from './headers';

/**
 * ListRescue is a component that displays a list of rescue data with various columns.
 * It fetches rescue enums and formats the data for display.
 *
 * @returns {JSX.Element} A JSX element representing the ListRescue component.
 */
const ListRescue = () => {
  const { t } = useTranslation();
  const { formattedData } = useFormatter();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [rescueEnums, setRescueEnums] = useState({});

  const getRescueEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/research/enums`
      });
      setRescueEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getRescueEnums();
    })();
  }, []);

  const columns = [
    {
      title: t('rescue.column.status'),
      key: 'status',
      dataIndex: 'status',
      render: (status) => status && t(`rescue.form.enums.${status}`),
      filters: (rescueEnums?.status || []).map((p) => ({
        text: t(`rescue.form.enums.${p}`),
        value: p
      })),
      sorter: true
    },
    {
      title: t('rescue.column.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('rescue.column.sourcePost'),
      key: 'sourcePost',
      dataIndex: 'sourcePost',
      render: (sourcePost) => (
        <Link
          to={{
            pathname: `${routes.SOURCEPOSTS}/show/${sourcePost?._id}`
          }}
        >
          {sourcePost?.name}
        </Link>
      ),
      sorter: true
    },
    {
      title: t('rescue.column.created_at'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (created_at) =>
        (created_at && moment(created_at).format('DD/MM/YYYY')) || '-',
      sorter: true
    }
  ];
  return (
    <ListResource
      resourceName="rescue"
      populate="sourcePost,contact"
      columns={columns}
      headers={headers}
      formatter={formattedData}
      canEditOrDeleteFct={(sourcePost) =>
        user.role === 'admins:SUPER-ADMIN' ||
        sourcePost?.contact?._id === user._id
      }
      resourceModelName="rescue"
    />
  );
};

export default ListRescue;
