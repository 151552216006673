import React from 'react';
import { Switch, Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Custom hook for generating columns for historical data.
 *
 * @param {Object} options - Options object
 * @param {Function} options.openModal - Function to open the modification modal
 * @param {boolean} options.forceRefresh - Boolean to force refresh
 * @param {Function} options.setForceRefresh - Function to set the force refresh state
 * @param {string} options.currentMode - Current mode (e.g., Sourcepost, Research, Project, Rescue)
 * @param {string} options.id - ID of the selected source post
 * @param {Function} options.message - Function to display error messages
 * @returns {Array} Array of column configurations
 */
export const useColumns = ({
  openModal,
  forceRefresh,
  setForceRefresh,
  currentMode,
  id,
  message
}) => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const typeEnums = ['PATCH', 'CREATE', 'DELETE'];
  const isSuperAdmin = user.role === 'admins:SUPER-ADMIN';

  const sorter = (a, b, sortOrder, key) => {
    const keyArray = key.split('.');

    if (key === 'date') {
      const filteredA = new Date(a.date);
      const filteredB = new Date(b.date);
      return filteredA - filteredB;
    }
    let filteredA = a[keyArray[0]];
    let filteredB = b[keyArray[0]];
    if (keyArray.length > 1) {
      filteredA = filteredA[keyArray[1]];
      filteredB = filteredB[keyArray[1]];
    }
    if (sortOrder === 'ascend') {
      return filteredA.localeCompare(filteredB);
    }
    return -filteredA.localeCompare(filteredB);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`;
    const monthCorrected = month.length < 2 ? `0${month}` : month;
    const day = `${d.getDate()}`;
    const year = d.getFullYear();

    return [day, monthCorrected, year].join('/');
  };

  const typeRenderer = (type) => {
    switch (type.toLowerCase()) {
      case 'create':
        return 'Création';
      case 'patch':
        return 'Modification';
      case 'delete':
        return 'Suppression';
      default:
        return '';
    }
  };

  const patchPinHistoric = async (checked, data) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/history/pin/${id}?modelName=${currentMode}`,
        body: { ...data, pin: checked }
      });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return [
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      sorter: (a, b, sortOrder) => sorter(a, b, sortOrder, 'date'),
      render: (date) => formatDate(date),
      defaultSortOrder: 'descend'
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      sorter: (a, b, sortOrder) => sorter(a, b, sortOrder, 'type'),
      render: (type) => typeRenderer(type),
      filters: typeEnums.map((ty) => ({
        text: t(`history.tags.${ty}`),
        value: ty
      }))
    },
    {
      title: 'Elements',
      key: 'modelName',
      dataIndex: 'modelName',
      render: (modelName) => t(`history.column.${modelName}`),
      sorter: (a, b, sortOrder) => sorter(a, b, sortOrder, 'modelName')
    },
    {
      title: 'Détails',
      key: 'title',
      dataIndex: 'title',
      render: (title, data) =>
        title?.startsWith('Modification des données') ? (
          <Button onClick={() => openModal(data)} icon={<EyeOutlined />}>
            {title}
          </Button>
        ) : (
          `${title || '-'}`
        ),
      sorter: (a, b, sortOrder) => sorter(a, b, sortOrder, 'details')
    },
    {
      title: 'Auteur',
      key: 'author',
      dataIndex: 'author',
      sorter: (a, b, sortOrder) => sorter(a, b, sortOrder, 'author.first_name'),
      render: (author) => `${author.first_name} ${author.last_name}` || '-'
    },
    {
      title: 'Épingler',
      key: 'pin',
      dataIndex: 'pin',
      render: (pin, data) =>
        data && (
          <Switch
            disabled={!isSuperAdmin}
            checkedChildren="Oui"
            unCheckedChildren="Non"
            defaultChecked={pin}
            onChange={(checked) => patchPinHistoric(checked, data)}
          />
        )
    }
  ];
};
