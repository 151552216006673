import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Switch, Button } from 'antd';
import { EditOutlined, EyeOutlined, CopyOutlined } from '@ant-design/icons';
import { routes } from '../../../utils/constants/adminRoutes';
import { PageHeaderCustom } from '../../../components';
import { ListResource } from '../../../components/ListResource/ListResource';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useErrorMessage } from '../../../utils/ErrorMessage';
import UpdateDepartProjectModal from './UpdateDepartProjectModal';

/**
 * Renders the ShowProject component.
 * @param {object} props - The props object.
 * @param {string} props.model - The model of the project.
 * @returns {JSX.Element} The JSX element representing the ShowProject component.
 */
const ShowProject = ({ model }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const [dataToUpdate, setDataToUpdate] = useState({});
  const [purpose, setPurpose] = useState('update');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [project, setProject] = useState([]);
  const [sourcePosts, setSourcePosts] = useState([]);
  const [departsLinked, setDepartsLinked] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPatchLoading, setIsPatchLoading] = useState(false);
  const [changeImpactedId, setChangeImpactedId] = useState(null);

  const openModal = (data, type) => {
    setPurpose(type);
    setDataToUpdate(data);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsLoading(true);
    setPurpose('update');
    setDataToUpdate({});
    setIsModalOpen(false);
    window.location.reload();
    setIsLoading(false);
  };

  const getChangeImpactedId = (selectedValue) => {
    let selectedChange;
    selectedChange = project.changes.find((f) => f._id === selectedValue);
    if (!selectedChange) {
      selectedChange = project.changes
        .filter((f) => f.depart === selectedValue)
        .filter((fi) => !fi.duplicate);
      setChangeImpactedId(selectedChange[0]?._id);
      return;
    }
    setChangeImpactedId(selectedChange?._id);
  };

  const getLinkedDeparts = (idSourcePost) => {
    setIsLoading(true);
    const departs = sourcePosts.find((f) => f._id === idSourcePost);
    setDepartsLinked(
      departs?.departData.filter((f) => f.title !== dataToUpdate.title)
    );
    const duplicateDeparts = project.changes.filter(
      (f) => f.duplicate === true
    );
    if (duplicateDeparts.length) {
      const newDepartsLinked = departs?.departData.filter(
        (f) => f.title !== dataToUpdate.title
      );
      duplicateDeparts.map((duplicate) =>
        newDepartsLinked.push({
          ...duplicate,
          title: `${duplicate.title} dupliqué`
        })
      );
      setDepartsLinked(newDepartsLinked);
    }
    setIsLoading(false);
  };

  const getSourcePosts = async () => {
    try {
      setIsLoading(true);
      const { data } = await dispatchAPI('GET', {
        url: `/${model}?populate=departData`
      });
      setSourcePosts(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  };

  const patchChangesProject = async (body, departId) => {
    setIsPatchLoading(true);
    try {
      // eslint-disable-next-line no-param-reassign
      if (purpose === 'duplicate') body.duplicate = true;
      const url =
        purpose === 'update'
          ? `/projects/changes/${id}?departId=${departId}&sourcePostId=${project.sourcePost._id}&changeImpactedId=${changeImpactedId}`
          : `/projects/duplicate/${id}`;
      await dispatchAPI('PATCH', {
        url,
        body
      });
      closeModal();
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsPatchLoading(false);
  };

  const getProject = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/projects/${id}?populate=sourcePost`
      });
      setProject(data);
      if (data) getSourcePosts();
    } catch (e) {
      message(e);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await getProject();
    })();
  }, [getProject]);

  const headers = [
    {
      label: 'depart',
      key: 'cell'
    },
    {
      label: 'projectPowerNonFois',
      key: 'projectPowerNonFois'
    },
    {
      label: 'projectPowerFois',
      key: 'projectPowerFois'
    },
    {
      label: 'coefFois',
      key: 'coefFois'
    },
    {
      label: 'typeOfCharge',
      key: 'typeOfCharge'
    },
    {
      label: 'isUsed',
      key: 'isUsed'
    }
  ];

  const columns = [
    {
      title: t('projects.column.depart'),
      key: 'title',
      dataIndex: 'title',
      sorter: true
    },
    {
      title: t('projects.column.projectPowerNonFois'),
      key: 'projectPowerNonFois',
      dataIndex: 'projectPowerNonFois',
      sorter: true
    },
    {
      title: t('projects.column.projectPowerFois'),
      key: 'projectPowerFois',
      dataIndex: 'projectPowerFois',
      sorter: true
    },
    {
      title: t('projects.column.coefFois'),
      key: 'coefFois',
      dataIndex: 'coefFois',
      sorter: true
    },
    {
      title: t('projects.column.isUsed'),
      key: 'isUsed',
      dataIndex: 'isUsed',
      render: (isUsed, data) =>
        data && (
          <Switch
            checkedChildren="Oui"
            unCheckedChildren="Non"
            defaultChecked={isUsed}
            onChange={(checked) =>
              patchChangesProject({ ...data, isUsed: checked }, data.depart)
            }
          />
        ),
      sorter: true
    },
    {
      title: t('projects.column.typeOfCharge'),
      key: 'typeOfCharge',
      dataIndex: 'typeOfCharge'
    },
    {
      title: t('projects.column.project_impacted'),
      key: 'sourcePost_impacted',
      dataIndex: 'sourcePost_impacted',
      render: (sourcePost_impacted) =>
        sourcePost_impacted && (
          <Link
            to={{
              pathname: `${routes.SOURCEPOSTS}/show/${sourcePost_impacted}`
            }}
          >
            <Button type="link">
              {`${t('projects.form.link_project')} `}
              <EyeOutlined />
            </Button>
          </Link>
        )
    },
    {
      title: t('projects.column.update'),
      key: 'depart',
      dataIndex: 'depart',
      render: (_, data) =>
        data && (
          <>
            <EditOutlined
              style={{ fontSize: 18, color: 'var(--primaryColor)' }}
              onClick={() => openModal(data, 'update')}
            />
            <CopyOutlined
              style={{
                fontSize: 18,
                color: 'var(--primaryColor)',
                marginLeft: 8
              }}
              onClick={() => openModal(data, 'duplicate')}
            />
          </>
        )
    }
  ];
  return (
    <>
      <PageHeaderCustom
        title={`${project?.name}`}
        extra={
          <Button type="primary">{`SP : ${
            project?.sourcePost?.name || 'N/A'
          }`}</Button>
        }
      />
      <UpdateDepartProjectModal
        dataToUpdate={dataToUpdate}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        patchChangesProject={patchChangesProject}
        sourcePosts={sourcePosts}
        getLinkedDeparts={getLinkedDeparts}
        departsLinked={departsLinked}
        isLoading={isLoading}
        isPatchLoading={isPatchLoading}
        purpose={purpose}
        getChangeImpactedId={getChangeImpactedId}
      />
      <ListResource
        resourceName="projects"
        extraQuery={`_id=${id}`}
        columns={columns}
        headers={headers}
        resourceModelName="Projects"
        showTitle={false}
        withCreateButton={false}
        withSearchButton={false}
        editAction={false}
        showAction={false}
        deleteAction={false}
        formattedData="changes"
        forceRefresh={forceRefresh}
        customPagination={100}
      />
    </>
  );
};

export default ShowProject;

ShowProject.propTypes = {
  model: PropTypes.string.isRequired
};
