import React from 'react';
import { Avatar, Tag } from 'antd';
import { userRoles } from '../../utils/constants/tagColors';

/**
 * Generates an array of objects representing the content of a profile list.
 *
 * @param {Object} data - The profile data to display.
 * @param {string} data.first_name - The first name of the user.
 * @param {string} data.last_name - The last name of the user.
 * @param {string} data.role - The role of the user.
 * @param {Object} data.phone_number - The phone number of the user.
 * @param {string} data.phone_number.country_code - The country code of the phone number.
 * @param {string} data.phone_number.number - The phone number.
 * @param {string} data.email - The email address of the user.
 * @param {Object} data.address - The address of the user.
 * @param {string} data.address.number - The street number of the address.
 * @param {string} data.address.street - The street name of the address.
 * @param {string} data.address.additional - Additional address information.
 * @param {string} data.address.postal_code - The postal code of the address.
 * @param {string} data.address.city - The city of the address.
 * @param {Function} t - The translation function.
 * @returns {Array} An array of objects representing the content of the profile list.
 * @returns {string} label - The label for the content item.
 * @returns {JSX.Element} content - The content to display.
 * @returns {number} span - The number of columns to span in the layout.
 */
const listContent = (
  { first_name, last_name, role, phone_number, email, address, photo },
  t
) => [
  {
    label: '',
    content: photo && <Avatar src={photo} size={120} />,
    span: 3
  },
  {
    label: 'profile.personalInfo.first_name',
    content: first_name,
    span: 3
  },
  {
    label: 'profile.personalInfo.last_name',
    content: last_name,
    span: 3
  },
  {
    label: 'profile.personalInfo.role',
    content: role && (
      <Tag color={userRoles[role.split(':')[1]]}>
        {t(`users.tags.${role.split(':')[1]}`)}
      </Tag>
    ),
    span: 3
  },
  {
    label: 'profile.form.phone_number',
    span: 3,
    content:
      phone_number && phone_number.number ? (
        <div>
          {`${phone_number.country_code} ${phone_number.number.slice(
            0,
            1
          )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
        </div>
      ) : (
        '-'
      )
  },
  {
    label: 'profile.personalInfo.email',
    content: email,
    span: 3
  },
  {
    label: 'profile.form.address',
    span: 3,
    content: address ? (
      <div>
        <p>
          {address.number}
          {address.street}
          <br />
          {address.additional}
          <br />
          {address.postal_code} {address.city}
        </p>
      </div>
    ) : (
      '-'
    )
  }
];

export default listContent;
