/**
 * Function to generate a list of source post data for display.
 * @param {object} data - The source post data.
 * @param {string} data.name - The name of the source post.
 * @param {string} data.code - The code of the source post.
 * @returns {Array} List of source post data.
 */
export const SourcePostList = (data) => {
  const { name, code } = data;

  return [
    {
      label: 'sourceposts.form.name',
      span: 1,
      content: name || '-'
    },
    {
      label: 'sourceposts.form.code',
      span: 1,
      content: code || '-'
    }
  ];
};
