import { Row, Tree } from 'antd';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSchemaContext } from './SchemaContext';
import { selectedButton, currentTitle } from '../utils/constants/schema';
import ShowElementModal from './ShowElementModal';
import TransformersElement from './TransformersElement';

/**
 * DisplaySchema component for rendering the schema tree.
 * @component
 * @param {object} props - The props for the DisplaySchema component.
 * @param {boolean} props.canEdit - Indicates if editing is allowed.
 * @param {Array} props.AutoTRArray - Array of automatic transformers.
 * @returns {JSX.Element} DisplaySchema component JSX
 */
const DisplaySchema = ({ canEdit, AutoTRArray }) => {
  const [checkedKeys, setCheckedKeys] = useState(['0-0-0']);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const {
    schema,
    selectedKeys,
    setSelectedKeys,
    setSelectedKeysInfo,
    setEditType,
    setCurrentStep,
    schemaRefreshed,
    setExpandedKeys,
    expandedKeys,
    setDataTreeSchema,
    dataTreeSchema,
    setPurpose,
    origin
  } = useSchemaContext();

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };

  const onSelect = (selectedKeysValue, info) => {
    if (selectedKeysValue.length) {
      setSelectedKeys(selectedKeysValue);
    }
    setSelectedKeysInfo(info);
    setCurrentStep(selectedButton[info.node.type]);
    setEditType(currentTitle[info.node.type]);
    setPurpose('init');
  };

  const onLoadData = () =>
    new Promise((resolve) => {
      setTimeout(() => {
        setDataTreeSchema([...schema.transformers]);
        resolve();
      }, 100);
    });

  useEffect(() => {
    (async () => {
      await onLoadData();
    })();
  }, [schemaRefreshed]);

  return (
    <Row className="schema-edit-elements">
      <TransformersElement
        canEdit={canEdit}
        origin={origin}
        AutoTRArray={AutoTRArray}
      />
      <Tree
        checkable={canEdit}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onCheck={onCheck}
        checkedKeys={checkedKeys}
        onSelect={onSelect}
        selectedKeys={selectedKeys}
        treeData={dataTreeSchema || []}
      />
      <ShowElementModal />
    </Row>
  );
};
export default DisplaySchema;

DisplaySchema.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  AutoTRArray: PropTypes.arrayOf(PropTypes.shape({}))
};

DisplaySchema.defaultProps = {
  AutoTRArray: []
};
