import React from 'react';
import { Input, InputNumber, Switch, DatePicker } from 'antd';

/**
 * Custom hook that generates form fields based on the element type.
 * @param {object} t - The translation function.
 * @param {string} element - The type of element (e.g., transformer, demirame, depart, autotransformer).
 * @param {object[]} autoTR - Array of automatic transformers.
 * @returns {object} An object containing fields for the form.
 * @returns {object[]} fields - An array of field objects for the form.
 */
const useFields = (t, element, autoTR) => {
  const fields = [
    ...(element !== 'autotransformer'
      ? [
          {
            name: ['title'],
            label: 'name',
            rules: [{ required: true }],
            input: <Input disabled />
          },
          {
            name: ['historic'],
            rules: [{ required: true }],
            initialValue: true,
            hidden: true
          }
        ]
      : []),
    ...(element === 'transformer'
      ? [
          {
            name: ['nominalPower'],
            rules: [{ required: true }],
            input: <InputNumber />
          },
          {
            name: ['voltageHTB'],
            rules: [{ required: true }],
            input: <InputNumber />
          },
          {
            name: ['voltageHTA'],
            rules: [{ required: true }],
            input: <InputNumber />
          },
          {
            name: ['voltageSet'],
            rules: [{ required: true }],
            input: <InputNumber />
          },
          {
            name: ['serviceYear'],
            rules: [{ required: true }],
            input: <DatePicker picker="year" />
          }
        ]
      : []),
    ...(element === 'demirame'
      ? [
          {
            name: ['arrival1Name'],
            rules: [{ required: true }]
          },
          {
            name: ['arrival2Name'],
            rules: [{ required: true }]
          },
          {
            name: ['voltage'],
            rules: [],
            input: <InputNumber disabled={!autoTR?.length} />
          },
          {
            name: ['rameTechnology'],
            rules: [{ required: true }]
          },
          {
            name: ['cellsNumber'],
            rules: [{ required: true }],
            input: <InputNumber />
          }
        ]
      : []),
    ...(element === 'depart'
      ? [
          {
            name: ['departCodeGDO']
          },
          {
            name: ['libelle']
          },
          {
            name: ['libelleERABLESIG']
          },
          {
            name: ['libellePHILEAS']
          },
          {
            name: ['voltage'],
            rules: [],
            input: <InputNumber disabled />
          },
          {
            name: ['acc'],
            rules: [{ required: true }],
            input: <InputNumber />
          },
          {
            name: ['IAnormal'],
            rules: [{ required: true }],
            input: <InputNumber disabled />
          },
          {
            name: ['Iinit'],
            rules: [{ required: true }],
            input: <InputNumber disabled />
          },
          {
            name: ['tempSensible'],
            valuePropName: 'checked',
            input: (
              <Switch
                checkedChildren={t('depart.form.switch.yes')}
                unCheckedChildren={t('depart.form.switch.no')}
              />
            )
          }
        ]
      : []),
    ...(element === 'autotransformer'
      ? [
          {
            name: ['name'],
            input: <Input disabled />
          },
          {
            name: ['installedPower'],
            input: <InputNumber />
          },
          {
            name: ['u_sortie'],
            input: <InputNumber />
          }
        ]
      : [])
  ];

  return {
    fields
  };
};

export default useFields;
