import React, { useState, useEffect, useCallback } from 'react';
import { Input, Upload, Select } from 'antd';
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next';
import { CameraOutlined } from '@ant-design/icons/lib';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { useAuthContext } from '../../contexts/AuthContext';
import 'react-quill/dist/quill.snow.css';

const { Dragger } = Upload;
const { Option } = Select;

/**
 * Converts a file to base64.
 * @param {File} file - The file to convert.
 * @returns {Promise<string>} A promise that resolves to the base64 representation of the file.
 */
export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (e) => reject(e);
  });

/**
 * Hook for managing form fields related to articles.
 * @param {Object} props - The hook props.
 * @param {string} props.purpose - The purpose of the form.
 * @param {Function} props.setContent - Function to set the content.
 * @param {string} props.content - The content of the article.
 * @returns {Object} An object containing form fields and related state.
 */
export const useFields = ({ purpose, setContent, content }) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const [fileList, setFileList] = useState([]);
  const [base64, setBase64] = useState('');
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [regionalDirections, setRegionalDirections] = useState([]);
  const [users, setUsers] = useState([]);
  const isSuperAdmin = user.role === 'admins:SUPER-ADMIN';

  const getRegionalDirections = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/regionalDirection`
      });
      setRegionalDirections(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users'
      });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setIsFieldsLoading(true);
      await getRegionalDirections();
      await getUsers();
      setIsFieldsLoading(false);
    })();
  }, [getRegionalDirections]);

  const draggerProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const fileExtension = file.name.split('.').pop();
      if (
        fileExtension === 'png' ||
        fileExtension === 'PNG' ||
        fileExtension === 'jpg' ||
        fileExtension === 'JPG'
      ) {
        setFileList([...fileList, file]);
        const base = await fileToBase64(file);
        setBase64(base);
        return false;
      }
      message('Not a PNG or JPG file.');
      return true;
    },
    fileList
  };

  const fields = [
    {
      name: ['photo'],
      input: (
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <CameraOutlined style={{ color: 'var(--textColor)' }} />
          </p>
          <p className="ant-upload-text">{t('files.create.action')}</p>
        </Dragger>
      )
    },
    {
      name: ['regionalDirection'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(regionalDirections || []).map((rd) => (
            <Option key={rd._id} value={rd._id}>
              {rd.name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['principal'],
      rules: [{ required: true }]
    },
    {
      name: ['introducy'],
      rules: [{ required: true }]
    },
    {
      name: ['content'],
      rules: [{ required: true }],
      input: <ReactQuill theme="snow" value={content} onChange={setContent} />
    },
    ...(!isSuperAdmin
      ? [
          {
            name: ['contact'],
            input: <Input defaultValue={user._id} />,
            hidden: true
          }
        ]
      : []),
    ...(isSuperAdmin && purpose === 'edit'
      ? [
          {
            name: ['contact'],
            input: (
              <Select loading={isFieldsLoading}>
                {(users || []).map((item) => (
                  <Option key={item._id} value={item._id}>
                    {`${item.first_name} ${item.last_name}`}
                  </Option>
                ))}
              </Select>
            ),
            rules: [{ required: true }]
          }
        ]
      : [])
  ];

  return {
    fields,
    isFieldsLoading,
    base64
  };
};
