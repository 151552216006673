import React, { useEffect, useState } from 'react';
import { Row, Col, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { ListCard } from '../../components/ListCard/ListCard';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { headers } from './headers';

const { Option } = Select;

/**
 * Component for displaying a list of articles.
 * @returns {JSX.Element} The JSX element for rendering the list of articles.
 */
const ListArticles = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [regionalDirections, setRegionalDirections] = useState(null);
  const [selectRD, setSelectRD] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getRegionalDirection = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/regionaldirections`
      });
      setRegionalDirections(data);
    } catch (e) {
      message(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getRegionalDirection();
    })();
  }, []);

  const dataKeys = {
    card: {
      title: '',
      extraTitle: '',
      alt: 'principal',
      src: 'photo'
    },
    meta: {
      title: 'principal',
      extraTitle: 'created_at',
      description: 'introducy',
      content: 'content'
    }
  };

  return (
    <ListCard
      resourceName="articles"
      populate="regionalDirection"
      dataKeys={dataKeys}
      headers={headers}
      resourceModelName="Article"
      extraQuery={selectRD && `&regionalDirection=${selectRD}`}
      showTitle={false}
      numberOfCards={3}
      withImportButton={false}
      extraButtons={
        <Row gutter={[8, 0]}>
          <Col>
            <Select
              loading={isLoading}
              style={{ width: 250, marginRight: 25 }}
              placeholder={t('articles.buttons.selectRD')}
              onSelect={(data) => {
                setSelectRD(data);
              }}
            >
              {(regionalDirections || []).map((sp) => (
                <Option key={sp._id} value={sp._id}>
                  {sp.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      }
    />
  );
};

export default ListArticles;
