import React from 'react';
import { Typography, Select } from 'antd';

const { Option } = Select;

/**
 * Provides the content for the Mixed component.
 * @param {object} props - The props of the MixedContent component.
 * @param {string} props.editType - The edit type.
 * @param {Array} props.transformersElement - The transformers element array.
 * @param {function} props.setLinkSwitchInId - The function to set link switch in ID.
 * @param {function} props.setLinkSwitchIn - The function to set link switch in.
 * @param {string} props.linkSwitchIn - The link switch in.
 * @param {string} props.purpose - The purpose.
 * @param {object} props.selectedKeysInfo - The selected keys info.
 * @param {object} props.schema - The schema.
 * @param {function} props.setATData - The function to set AT data.
 * @param {object} props.ATData - The AT data.
 * @param {function} props.setLinkSwitchBetweenId - The function to set link switch between ID.
 * @param {function} props.setLinkSwitchBetween - The function to set link switch between.
 * @param {string} props.linkSwitchBetween - The link switch between.
 * @param {Function} props.t - The translation function.
 * @returns {Array} An array of objects representing the content for the Mixed component.
 */
export const MixedContent = ({
  editType,
  transformersElement,
  setLinkSwitchInId,
  setLinkSwitchIn,
  linkSwitchIn,
  purpose,
  selectedKeysInfo,
  schema,
  setATData,
  ATData,
  setLinkSwitchBetweenId,
  setLinkSwitchBetween,
  linkSwitchBetween,
  t
}) => [
  {
    key: 7,
    content:
      (editType === 'RA' && transformersElement.length && purpose !== 'edit') ||
      (editType === 'DE' &&
        purpose === 'edit' &&
        selectedKeysInfo.node.type !== 'DE' &&
        transformersElement.length) ? (
        <>
          <Typography>{t('schema.toolbar.link_transformer')}:</Typography>
          <Select
            style={{ width: 150, marginRight: 7 }}
            onSelect={(data) => {
              setLinkSwitchInId(transformersElement[data].id);
              setLinkSwitchIn(data);
            }}
            defaultValue={linkSwitchIn}
          >
            {(transformersElement || []).map((transformer) => (
              <Option key={transformer.key} value={transformer.key}>
                {transformer.title}
              </Option>
            ))}
          </Select>
        </>
      ) : (
        []
      )
  },
  {
    key: 8,
    content:
      (editType === 'RA' &&
        transformersElement.length &&
        selectedKeysInfo.node?.children?.length &&
        purpose !== 'edit') ||
      editType === 'AT' ||
      (editType === 'DE' &&
        purpose === 'edit' &&
        selectedKeysInfo.node.type !== 'DE' &&
        transformersElement.length &&
        schema.transformers[
          parseFloat(selectedKeysInfo.node.key?.toString().charAt(2))
        ].children.length > 1) ? (
        <>
          <Typography>{t('schema.toolbar.link_dr')}:</Typography>
          <Select
            style={{ width: 150 }}
            onSelect={(data) => {
              if (editType === 'AT') {
                setATData({
                  ...ATData,
                  demirame: data
                });
              } else {
                setLinkSwitchBetweenId(
                  schema.transformers[parseFloat(data?.toString().charAt(2))]
                    ?.children[parseFloat(data?.toString().slice(4))]?.id
                );
                setLinkSwitchBetween(data);
              }
            }}
            defaultValue={linkSwitchBetween}
          >
            {(editType === 'AT'
              ? schema?.transformers
                  ?.map((tr) => tr.id === ATData?.colonne && tr.children)
                  .flat()
              : (
                  (purpose === 'edit'
                    ? schema.transformers[
                        parseFloat(
                          selectedKeysInfo.node.key?.toString().charAt(2)
                        )
                      ]?.children
                    : selectedKeysInfo?.node?.children) || []
                ).filter(
                  (element) => element.title !== selectedKeysInfo.node.title
                )
            )?.map((parent) => (
              <Option
                key={parent.key}
                value={editType === 'AT' ? parent.id : parent.key}
              >
                {parent.title}
              </Option>
            ))}
          </Select>
        </>
      ) : (
        []
      )
  }
];
