import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { projectStatus } from '../../utils/constants/tagColors';

/**
 * Custom hook for generating columns for D12 transfers.
 *
 * @param {Object} options - Options object
 * @param {Array} options.projectEnums - Array of project enums
 * @param {Array} options.yearsColumn - Array of years column data
 * @returns {Array} Array of column configurations
 */
export const useTransferColumns = ({ projectEnums, yearsColumn }) => {
  const { t } = useTranslation();

  return [
    {
      title: t('D12.column.EBR'),
      key: 'ebr',
      dataIndex: 'ebr',
      sorter: true
    },
    {
      title: t('D12.column.department'),
      key: 'department',
      dataIndex: 'department',
      sorter: true
    },
    {
      title: t('D12.column.codeGdo'),
      key: 'codeGdo',
      dataIndex: 'codeGdo',
      render: (codeGdo, data) => `${codeGdo}-${data.voltage}`,
      sorter: true
    },
    {
      title: t('D12.column.department_impacted'),
      key: 'department_impacted',
      dataIndex: 'department_impacted',
      sorter: true
    },
    {
      title: t('D12.column.gdo_htb_impacted'),
      key: 'gdo_htb_impacted',
      dataIndex: 'gdo_htb_impacted',
      sorter: true
    },
    {
      title: t('D12.column.case_identifier'),
      key: 'case_identifier',
      dataIndex: 'case_identifier',
      sorter: true
    },
    {
      title: t('projects.column.case_status'),
      key: 'case_status',
      dataIndex: 'case_status',
      render: (case_status) => (
        <Tag color={projectStatus[case_status]}>
          {t(`projects.form.enums.${case_status}`)}
        </Tag>
      ),
      filters: (projectEnums?.caseStatus || []).map((c) => ({
        text: t(`projects.form.enums.${c}`),
        value: c
      })),
      sorter: true
    },
    ...(yearsColumn || []),
    {
      title: t('D12.column.comment'),
      key: 'comment',
      dataIndex: 'changes',
      render: (changes) => changes && changes.comment,
      sorter: true
    }
  ];
};
