import React, { useState, useEffect } from 'react';
import { Row, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useSchemaContext } from '../SchemaContext';

/**
 * TransformersMapping component
 *
 * Renders a mapping of transformers with their associated data and controls.
 *
 * @param {Object} props - The properties object.
 * @param {number} props.width - The width of each transformer element.
 * @param {string} props.mode - The mode in which the component is operating.
 * @param {Array} props.totals - The array containing total values for each transformer.
 * @param {boolean} props.canDisableTR - Flag indicating whether transformers can be disabled.
 * @param {Object} props.projection - The projection object for transformations.
 * @param {function} props.setLocationTransfo - Function to set the location of the transformation.
 * @param {function} props.handleData - Function to handle data retrieval and formatting.
 * @param {Object} props.elementsRef - Reference to the elements.
 *
 * @return {JSX.Element|null} The rendered transformers mapping component or null if no transformers are provided.
 */
const TransformersMapping = ({
  width,
  mode,
  totals,
  canDisableTR,
  projection,
  setLocationTransfo,
  handleData,
  elementsRef
}) => {
  const { transformersElement } = useSchemaContext();
  const [isDisabled, setIsDisabled] = useState(false);

  const transfoLocationInfo = (el, item) => {
    if (el) {
      // eslint-disable-next-line no-param-reassign
      elementsRef.current[item.title] = {
        title: item.title,
        _id: item.id,
        location: el.getBoundingClientRect(),
        color: item.color
      };
    }
  };

  useEffect(() => {
    setLocationTransfo((prev) => ({
      ...prev,
      ...elementsRef.current
    }));
  }, [transformersElement, setLocationTransfo]);

  return transformersElement?.map((item) => (
    <Tooltip
      key={item.id}
      placement="bottom"
      title={
        <div>
          <p style={{ marginBottom: 5 }}>
            {`Tension de consigne: ${
              totals
                ?.find((el) => el._id === item.id)
                ?.voltageSet?.toFixed(2) || 0
            } kV`}
          </p>
          <p style={{ marginBottom: 5 }}>{`Année de MES: ${moment(
            totals?.find((el) => el._id === item.id)?.serviceYear
          ).format('YYYY')}`}</p>
        </div>
      }
    >
      <div
        key={item.id}
        style={{ width }}
        ref={(el) => transfoLocationInfo(el, item)}
        className="transformer-wrapper"
      >
        <h2
          className="transformer-title"
          style={{ background: isDisabled === item.id ? 'grey' : item.color }}
        >
          {item.title}
          {canDisableTR && (
            <button
              type="button"
              style={{
                background: 'rgba(0, 0, 0, 0)',
                border: 'none'
              }}
              onClick={() => setIsDisabled(item.id)}
            >
              <CloseOutlined
                style={{ background: 'rgba(0, 0, 0, 0)', color: 'red' }}
              />
            </button>
          )}
        </h2>
        <table className="schema-table">
          <tbody>
            <tr>
              <td rowSpan="4">S Courant</td>
              <th>
                <Row justify="space-between">
                  <span
                    style={{
                      color: 'var(--disabledColor)',
                      paddingLeft: '5px'
                    }}
                  >
                    Stmb
                  </span>
                  <span style={{ paddingRight: '5px' }}>
                    {handleData(
                      item.id,
                      'puissance-total',
                      mode,
                      totals,
                      projection
                    )}
                  </span>
                </Row>
              </th>
            </tr>
            <tr>
              <th>
                <Row justify="space-between">
                  <span
                    style={{
                      color: 'var(--disabledColor)',
                      paddingLeft: '5px'
                    }}
                  >
                    Itmb
                  </span>
                  <span style={{ paddingRight: '5px' }}>
                    {handleData(
                      item.id,
                      'transit-total',
                      mode,
                      totals,
                      projection
                    )}
                  </span>
                </Row>
              </th>
            </tr>
            <tr>
              <th>
                <Row justify="space-between">
                  <span
                    style={{
                      color: 'var(--disabledColor)',
                      paddingLeft: '5px'
                    }}
                  >
                    Pn
                  </span>
                  <span style={{ paddingRight: '5px' }}>
                    {`${
                      totals
                        ?.find((el) => el._id === item.id)
                        ?.nominalPower?.toFixed(2) || 0
                    } MVA`}
                  </span>
                </Row>
              </th>
            </tr>
            <tr>
              <th>
                <Row justify="end">
                  {handleData(item.id, 'percent', mode, totals, projection)}
                </Row>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </Tooltip>
  ));
};

export default TransformersMapping;
