import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { ListResource } from '../../components';
import { userRoles, userStatus } from '../../utils/constants/tagColors';
import { useAuthContext } from '../../contexts/AuthContext';

/**
 * Displays a list of users with their details.
 *
 * @component
 * @returns {JSX.Element} A JSX element representing the ListUsers component.
 */
const ListUsers = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const isSuperAdmin = user.role === 'admins:SUPER-ADMIN';

  const headers = [
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'phone_number.country_code',
      key: 'phone_number.country_code'
    },
    {
      label: 'phone_number.number',
      key: 'phone_number.number'
    },
    {
      label: 'role',
      key: 'role'
    },
    {
      label: 'position',
      key: 'position'
    }
  ];

  const columns = [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.role'),
      key: 'role',
      dataIndex: 'role',
      render: (role) => (
        <Tag color={userRoles[role.split(':')[1]]}>
          {t(`users.tags.${role.split(':')[1]}`)}
        </Tag>
      ),
      sorter: true,
      filters: Object.keys(userRoles).map((r) => ({
        text: t(`users.tags.${r}`),
        value:
          r.split('-').length > 1
            ? `${r.split('-')[1].toLowerCase()}s:${r}`
            : `${r.toLowerCase()}s:${r}`
      }))
    },

    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    },
    ...(isSuperAdmin
      ? [
          {
            title: t('users.form.status'),
            key: 'status',
            dataIndex: 'status',
            render: (status) => (
              <Tag color={userStatus[status]}>{t(`users.tags.${status}`)}</Tag>
            ),
            sorter: true,
            filters: Object.keys(userStatus).map((s) => ({
              text: t(`users.tags.${s}`),
              value: s
            }))
          }
        ]
      : [])
  ];

  return (
    <ListResource
      resourceName="users"
      columns={columns}
      headers={headers}
      resourceModelName="User"
    />
  );
};

export default ListUsers;
