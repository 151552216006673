import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../../components/ListResource/ListResource';

/**
 * Component that displays depart data in a list format.
 * @param {object} props - The props object.
 * @param {string} props.id - The ID of the source post.
 * @param {string} props.origin - The origin of the data.
 * @returns {JSX.Element} React component.
 */
const DemirameData = ({ id, origin }) => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'sourcePost',
      key: 'sourcePost.name'
    },
    {
      label: 'demiRame',
      key: 'demiRame.title'
    },
    {
      label: 'title',
      key: 'title'
    },
    {
      label: 'departCodeGDO',
      key: 'departCodeGDO'
    },
    {
      label: 'libelle',
      key: 'libelle'
    },
    {
      label: 'libelleERABLESIG',
      key: 'libelleERABLESIG'
    },
    {
      label: 'libellePHILEAS',
      key: 'libellePHILEAS'
    },
    {
      label: 'voltage',
      key: 'voltage'
    },
    {
      label: 'acc',
      key: 'acc'
    },
    {
      label: 'tempSensible',
      key: 'tempSensible'
    },
    {
      label: 'createdBy',
      key: 'createdBy.first_name'
    },
    {
      label: 'createdBy',
      key: 'createdBy.last_name'
    }
  ];

  const columns = [
    {
      title: t('sourceposts.show.departData.sourcePost'),
      key: 'sourcePost',
      dataIndex: 'sourcePost',
      render: ({ name }) => name,
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.demiRame'),
      key: 'demiRame',
      dataIndex: 'demiRame',
      render: ({ title }) => title,
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.cell'),
      key: 'title',
      dataIndex: 'title',
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.departCodeGDO'),
      key: 'departCodeGDO',
      dataIndex: 'departCodeGDO',
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.libelle'),
      key: 'libelle',
      dataIndex: 'libelle',
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.libelleERABLESIG'),
      key: 'libelleERABLESIG',
      dataIndex: 'libelleERABLESIG',
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.libellePHILEAS'),
      key: 'libellePHILEAS',
      dataIndex: 'libellePHILEAS',
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.voltage'),
      key: 'voltage',
      dataIndex: 'voltage',
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.acc'),
      key: 'acc',
      dataIndex: 'acc',
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.tempSensible'),
      key: 'tempSensible',
      dataIndex: 'tempSensible',
      render: (tempSensible) => `${t(`depart.form.switch.${tempSensible}`)}`,
      sorter: true
    },
    {
      title: t('sourceposts.show.departData.createdBy'),
      key: 'createdBy',
      dataIndex: 'createdBy',
      render: ({ first_name, last_name }) => `${first_name} ${last_name}`,
      sorter: true
    }
  ];
  return (
    <ListResource
      withCreateButton={false}
      showAction={false}
      deleteAction={false}
      resourceName="departs"
      columns={columns}
      headers={headers}
      populate="sourcePost,demiRame,createdBy,updatedBy"
      extraQuery={`sourcePost=${id}&model=${origin}`}
      resourceModelName="Depart"
      showTitle={false}
      path={`/${origin}/depart`}
      withImportButton={false}
    />
  );
};

export default DemirameData;

DemirameData.propTypes = {
  id: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired
};
