import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { routes, subRoutes } from '../../../utils/constants/adminRoutes';
import { ListResource } from '../../../components/ListResource/ListResource';
import useSourcePostContext from '../SourcePostContext';
import { projectStatus } from '../../../utils/constants/tagColors';
import { useFormatter } from '../../../utils/formatter';
import useResearchContext from '../../research/ResearchContext';
import useRescueContext from '../../rescue/RescueContext';

/**
 * Renders the Projects component.
 * @param {object} props - The props object.
 * @param {string} props.id - The ID of the source post.
 * @param {string} props.origin - The origin of the source post.
 * @returns {JSX.Element} The JSX element representing the Projects component.
 */
const Projects = ({ id, origin }) => {
  const { t } = useTranslation();
  const { formattedData } = useFormatter();
  const sourcePostContext = useSourcePostContext();
  const researchContext = useResearchContext();
  const rescueContext = useRescueContext();
  const projectEnums =
    sourcePostContext?.projectEnums ||
    researchContext?.projectEnums ||
    rescueContext?.projectEnums;

  const switchUrl = {
    sourceposts: 'SOURCEPOSTS',
    research: 'RESEARCH',
    rescue: 'RESCUE'
  };

  const headers = [
    {
      label: 'status',
      key: 'status'
    },
    {
      label: 'name',
      key: 'name'
    },
    {
      label: 'case_status',
      key: 'case_status'
    },
    {
      label: 'case_identifier',
      key: 'case_identifier'
    },
    {
      label: 'yearMES',
      key: 'yearMES'
    },
    {
      label: 'yearTO',
      key: 'yearTO'
    },
    {
      label: 'achievmentTime',
      key: 'achievmentTime'
    },
    {
      label: 'created_at',
      key: 'created_at'
    }
  ];

  const columns = [
    {
      title: t('projects.column.status'),
      key: 'status',
      dataIndex: 'status',
      filters: (projectEnums?.status || []).map((p) => ({
        text: t(`projects.form.enums.${p}`),
        value: p
      })),
      render: (status) => (
        <Tag color={projectStatus[status]}>
          {t(`projects.form.enums.${status}`)}
        </Tag>
      ),
      sorter: true
    },
    {
      title: t('projects.column.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('projects.column.case_status'),
      key: 'case_status',
      dataIndex: 'case_status',
      render: (case_status) => (
        <Tag color={projectStatus[case_status]}>
          {t(`projects.form.enums.${case_status}`)}
        </Tag>
      ),
      filters: (projectEnums?.caseStatus || []).map((c) => ({
        text: t(`projects.form.enums.${c}`),
        value: c
      })),
      sorter: true
    },
    {
      title: t('projects.column.case_identifier'),
      key: 'case_identifier',
      dataIndex: 'case_identifier',
      sorter: true
    },
    {
      title: t('projects.column.yearMES'),
      key: 'yearMES',
      render: (yearMES) => (yearMES && moment(yearMES).format('YYYY')) || '-',
      dataIndex: 'yearMES',
      sorter: true
    },
    {
      title: t('projects.column.yearTO'),
      key: 'yearTO',
      render: (yearTO) =>
        (yearTO && moment(yearTO).format('DD/MM/YYYY')) || '-',
      dataIndex: 'yearTO',
      sorter: true
    },
    {
      title: t('projects.column.achievmentTime'),
      key: 'achievmentTime',
      dataIndex: 'achievmentTime',
      sorter: true
    },
    {
      title: t('projects.column.created_at'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (created_at) =>
        (created_at && moment(created_at).format('DD/MM/YYYY')) || '-',
      sorter: true
    }
  ];
  return (
    <ListResource
      resourceName="projects"
      populate="sourcePost"
      extraQuery={`sourcePost=${id}&model=${origin}`}
      columns={columns}
      headers={headers}
      resourceModelName="Projects"
      showTitle={false}
      createUrl={`/${origin}/projects`}
      path={`/${origin}/projects`}
      formatter={formattedData}
      withImportButton={false}
      showAction={{
        pathname: (record) =>
          `${routes[switchUrl[origin]]}${subRoutes.PROJECTS}/show/${
            record?._id
          }`
      }}
    />
  );
};

export default Projects;

Projects.propTypes = {
  id: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired
};
