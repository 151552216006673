// Main nav menuItems
export const routes = {
  HOME: '/',
  USERS: '/users',
  DR: '/directions-regionales',
  SOURCEPOSTS: '/sourceposts',
  D12: '/D12',
  RESEARCH: '/research',
  RESCUE: '/rescue',
  IMPORT: '/import',
  HISTORY: '/history',
  NEWS: '/news'
};

// Main nav subMenuItems
export const subRoutes = {
  PROJECTS: '/projects',
  WEATHER: '/weather',
  PRODUCER: '/producer',
  CONTRACTUALRELIEF: '/contractualrelief',
  TRANSFORMER: '/transformer',
  COLONNE: '/colonne',
  DEMIRAME: '/demirame',
  DEPART: '/depart',
  AT: '/autotransformer'
};

// Other routes
export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile',
  SETTINGS: '/settings'
};

// url search parameters
export const pathSearches = {
  USERS: '?f=status=ACTIVE__',
  SOURCEPOSTS: '?s=-created_at',
  D12: '?pS=100'
};
