import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ListResource } from '../../../components/ListResource/ListResource';

/**
 * Component for displaying contractual relief data.
 * @param {object} props - The props object.
 * @param {string} props.id - The ID of the resource.
 * @param {string} props.origin - The origin of the resource.
 * @returns {JSX.Element} The JSX element representing the ContractualRelief component.
 */
const ContractualRelief = ({ id, origin }) => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'sourcePost',
      key: 'sourcePost.name'
    },
    {
      label: 'customer',
      key: 'customer'
    },
    {
      label: 'depart',
      key: 'depart.title'
    },
    {
      label: 'contractNumber',
      key: 'contractNumber'
    },
    {
      label: 'contractualPower',
      key: 'contractualPower'
    },
    {
      label: 'contractualTMB',
      key: 'contractualTMB'
    },
    {
      label: 'contractualTN',
      key: 'contractualTN'
    },
    {
      label: 'powerTN',
      key: 'powerTN'
    },
    {
      label: 'powerTMB',
      key: 'powerTMB'
    },
    {
      label: 'yearMES',
      key: 'yearMES'
    },
    {
      label: 'comment',
      key: 'comment'
    }
  ];

  const columns = [
    {
      title: t('projects.column.sourcePost'),
      key: 'sourcePost',
      dataIndex: 'sourcePost',
      render: ({ name }) => name,
      sorter: true
    },
    {
      title: t('contractualrelief.column.customer'),
      key: 'customer',
      dataIndex: 'customer',
      sorter: true
    },
    {
      title: t('contractualrelief.column.depart'),
      key: 'depart',
      dataIndex: 'depart',
      render: (depart) => depart?.title || '-',
      sorter: true
    },
    {
      title: t('contractualrelief.column.contractNumber'),
      key: 'contractNumber',
      dataIndex: 'contractNumber',
      sorter: true
    },
    {
      title: t('contractualrelief.column.contractualPower'),
      key: 'contractualPower',
      dataIndex: 'contractualPower',
      sorter: true
    },
    {
      title: t('contractualrelief.column.contractualTMB'),
      key: 'contractualTMB',
      dataIndex: 'contractualTMB',
      sorter: true
    },
    {
      title: t('contractualrelief.column.contractualTN'),
      key: 'contractualTN',
      dataIndex: 'contractualTN',
      sorter: true
    },
    {
      title: t('contractualrelief.column.powerTN'),
      key: 'powerTN',
      dataIndex: 'powerTN',
      sorter: true
    },
    {
      title: t('contractualrelief.column.powerTMB'),
      key: 'powerTMB',
      dataIndex: 'powerTMB',
      sorter: true
    },
    {
      title: t('contractualrelief.column.yearMES'),
      key: 'yearMES',
      dataIndex: 'yearMES',
      render: (yearMES) => (yearMES && moment(yearMES).format('YYYY')) || '-',
      sorter: true
    },
    {
      title: t('contractualrelief.column.comment'),
      key: 'comment',
      dataIndex: 'comment',
      sorter: true
    }
  ];
  return (
    <ListResource
      resourceName="contractualrelief"
      populate="sourcePost,depart"
      extraQuery={`sourcePost=${id}&model=${origin}`}
      columns={columns}
      headers={headers}
      resourceModelName="Contractualrelief"
      showTitle={false}
      createUrl={`/${origin}/contractualrelief`}
      path={`/${origin}/contractualrelief`}
      showAction={false}
    />
  );
};

export default ContractualRelief;

ContractualRelief.propTypes = {
  id: PropTypes.string.isRequired,
  origin: PropTypes.string.isRequired
};
