import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ListArticles from './ListArticles';
import CreateUpdateArticle from './CreateUpdateArticle';
import ShowArticle from './ShowArticle';

/**
 * Component to define route for blog.
 * @returns {JSX.Element} Router component for blog.
 */
export const ArticleRouter = () => (
  <Routes>
    <Route path="/create" element={<CreateUpdateArticle purpose="create" />} />
    <Route path="/edit/:id" element={<CreateUpdateArticle purpose="edit" />} />
    <Route path="/show/:id" element={<ShowArticle />} />
    <Route index element={<ListArticles />} />
  </Routes>
);
