import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Popconfirm, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import { PageHeaderCustom } from '../../components';
import { useAuthContext } from '../../contexts/AuthContext';
import { useErrorMessage } from '../../utils/ErrorMessage';
import { SchemaContextProvider } from '../../schemaTool/SchemaContext';
import useRescueContext from './RescueContext';
import Schema from '../../schemaTool/Schema';
import Projects from '../sourcePost/ShowSourcePost/Projects';
import PrincipalSourcePostData from '../sourcePost/ShowSourcePost/PrincipalSourcePostData';

const { TabPane } = Tabs;

/**
 * Displays detailed information about a rescue.
 * Allows users with appropriate permissions to edit or delete the rescue.
 * Renders tabs for displaying the rescue schema, projects associated with the rescue,
 * and information about the principal source post data.
 *
 * @component
 * @returns {JSX.Element} A JSX element representing the ShowRescue component.
 */
const ShowRescue = () => {
  const { id } = useParams();
  const { dispatchAPI, user } = useAuthContext();
  const { selectedTab, setSelectedTab } = useRescueContext();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [rescue, setRescue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [yearInit, setYearInit] = useState();

  const findYearInit = (weatherDatas) => {
    const lastDate = new Date(
      Math.max(
        ...weatherDatas.map((weatherData) => new Date(weatherData.yearMES))
      )
    );
    setYearInit(Number(moment(lastDate).format('YYYY')));
  };

  useEffect(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/rescue/${id}`
      });
      setCanEdit(
        user.role === 'admins:SUPER-ADMIN' ||
          data?.contact === user._id ||
          data?.contact?._id === user._id
      );
    } catch (e) {
      message(e);
    }
  }, [user, id]);

  const getRescue = useCallback(
    async (source, idProject) => {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/${source}/${
            idProject || id
          }?populate=departData projects weatherData demiRameData`
        });
        if (data.weatherData.length) findYearInit(data.weatherData);
        setRescue(data);
      } catch (e) {
        message(e);
      }
      setIsLoading(false);
    },
    [id]
  );

  useEffect(() => {
    (async () => {
      await getRescue('rescue');
    })();
  }, [getRescue]);

  const deleteRescuePost = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/rescue/${id}` });
      navigate(-1);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const handleTabChange = (key) => {
    setSelectedTab(key);
  };

  const tabsContent = [
    {
      label: `Schéma`,
      key: '1',
      children: <Schema id={id} origin="rescue" />
    },
    {
      label: `Projets`,
      key: '2',
      children: <Projects id={id} origin="rescue" />
    },
    {
      label: `Informations sur le poste source`,
      key: '3',
      children: (
        <PrincipalSourcePostData
          sourcePost={rescue}
          isLoading={isLoading}
          id={id}
          origin="rescue"
        />
      )
    }
  ];

  return (
    <SchemaContextProvider
      sourcePost={rescue}
      getSourcePost={getRescue}
      yearInit={yearInit}
    >
      <PageHeaderCustom
        title={`${t('rescue.show.title')}: ${rescue?.name}`}
        extra={
          canEdit && (
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ type: 'danger' }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteRescuePost}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )
        }
      />
      <Tabs
        activeKey={selectedTab}
        onChange={handleTabChange}
        className="source-post-tabs"
      >
        {tabsContent.map((tab) => (
          <TabPane tab={tab.label} key={tab.key}>
            {tab.children}
          </TabPane>
        ))}
      </Tabs>
    </SchemaContextProvider>
  );
};

export default ShowRescue;
