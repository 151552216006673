import React from 'react';
import PropTypes from 'prop-types';
import { useFields } from './fields';
import { CreateUpdateContainer } from '../../components/CreateUpdateContainer/CreateUpdateContainer';

/**
 * Component for creating or updating a regional direction.
 *
 * @param {string} purpose - Purpose of the component, either 'create' or 'update'.
 * @returns {React.Element} React component
 */
const CreateUpdateDR = ({ purpose }) => {
  const { fields, isFieldsLoading } = useFields();

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="regionaldirections"
      resource="regionaldirections"
    />
  );
};

CreateUpdateDR.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateDR;
