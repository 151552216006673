import React from 'react';
import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { useSchemaContext } from './SchemaContext';
import { elementLink } from '../utils/constants/schema';
import { elementList } from './listContent';
import { DescriptionList } from '../components';

/**
 * Modal component for displaying schema elements.
 * @component
 * @returns {JSX.Element} - The rendered component.
 */
const ShowElementModal = () => {
  const { t } = useTranslation();
  const { modalVisible, setModalVisible, selectedKeysInfo, elementData } =
    useSchemaContext();

  return (
    <Modal
      title={`${t('schema.modal.title')} ${t(
        `schema.modal.selected.${selectedKeysInfo?.node?.type}`
      )} "${selectedKeysInfo?.node?.title}"`}
      centered
      visible={modalVisible}
      width={500}
      onCancel={() => {
        setModalVisible(false);
      }}
      footer={[
        <Link
          to={{
            pathname: `/sourceposts/${
              elementLink[selectedKeysInfo?.node?.type]
            }/edit/${elementData?._id}`
          }}
          style={{ display: 'inline-flex', gap: 8, alignItems: 'center' }}
        >
          {t('schema.modal.edit')} <EditOutlined />
        </Link>
      ]}
    >
      <DescriptionList
        data={elementList(
          elementData || {},
          t,
          elementLink[selectedKeysInfo?.node?.type]
        )}
        translate
      />
    </Modal>
  );
};

export default ShowElementModal;
